import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Topbar from 'components/Topbar/Topbar';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import styles from 'pages/CorrectAnswer/CorrectAnswer.scss';
import { medalPoints, characters } from 'config';

import { setUser } from 'pages/App/App.state';

function CorrectAnswer({
  dispatch,
  history,
  user: { points, character, medal, completedQuests },
  quests,
}) {
  let showMedal = null;
  if (!medal && points > medalPoints) {
    showMedal = (
      <div className={styles.medalPoints}>
        <p>Du hast die Forschermedaille erworben!</p>
        <Icon
          glyph={characters[character].icon}
          className={styles.characterIcon}
        />
      </div>
    );
  }

  const gameFinished = completedQuests.length === quests.length;

  return (
    <div className={styles.container}>
      <Topbar />
      <div className={styles.content}>
        <div>
          <div className={styles.header}>
            <h2 className={styles.heading}>
              NATURDENKMÄLER WIEN <br />
              und GMI
            </h2>
            <h1 className={styles.correctAnswer}>
              Gratulation <br /> Richtige Antwort
            </h1>
          </div>
        </div>

        {gameFinished ? (
          <div className={styles.gameFinished}>
            <p>
              Du hast das Spiel durchgespielt und {points} Punkte gesammelt!
            </p>
            <Icon
              glyph={characters[character].icon}
              className={styles.characterIcon}
            />
            <div>
              <Button to="/profile" view="outline">
                Beim Highscore einreichen
              </Button>
            </div>
            <p className={styles.thanksForPlaying}>Danke für das Spielen!</p>
          </div>
        ) : (
          <Fragment>
            <div className={styles.buttonWrapper}>
              {showMedal}
              <Button
                onClick={() => {
                  dispatch(
                    setUser({
                      medal: points > medalPoints,
                    }),
                  );
                  history.push('/monuments');
                }}
                view="outline"
              >
                zum nächsten Quest
              </Button>
            </div>
            <div className={styles.hintText}>
              <p>
                <span>Hier gelangst du in die Übersicht</span> <br />{' '}
                <strong>ORTE/BÄUME.</strong>
              </p>
              <p>
                <span>
                  Du kannst auch direkt in die <br /> Navigation{' '}
                </span>{' '}
                <strong>WO</strong> <span>gehen.</span>
              </p>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ app: { user, quests } }) => ({
  user,
  quests,
});

export default connect(mapStateToProps)(CorrectAnswer);
