import React from 'react';
import Heading from 'components/Heading/Heading';
import Topbar from 'components/Topbar/Topbar';

import styles from 'pages/Policy/Policy.scss';
import Content from 'components/Content/Content';

import { addGtagToDom, removeGtagFromDom } from 'components/Banner/Banner';

function Policy() {
  const [state, setState] = React.useState(
    () => localStorage.getItem('cc-ndqust') || 'inital',
  );
  const status = state === 'accepted' ? 'erlaubt!' : ' nicht erlaubt!';

  const handleStatusChange = () => {
    if (state === 'accepted') {
      setState('rejected');
      removeGtagFromDom();
    }

    if (state === 'rejected') {
      setState('accepted');
      addGtagToDom();
    }
  };

  return (
    <div>
      <Topbar />
      <div className={styles.container}>
        <Heading tag="h2" className={styles.heading}>
          Datenschutz
        </Heading>
        <Content className={styles.content}>
          <p>
            Google Analytics ist {status}{' '}
            <button type="button" onClick={handleStatusChange}>
              {state === 'accepted' ? 'Nicht erlauben' : 'Erlauben'}
            </button>
          </p>
          <p>
            Diese Website benutzt Google Analytics, einen Webanalysedienst der
            Google Inc. (&quot;Google&quot;). Google Analytics verwendet sog.
            &quot;Cookies &quot;, Textdateien, die auf Ihrem Computer
            gespeichert werden und die eine Analyse der Benutzung der Website
            durch Sie ermöglichen. Die durch den Cookies erzeugten Informationen
            über Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse)
            werden an einen Server von Google in den USA übertragen und dort
            gespeichert. Google wird diese Informationen benutzen, um Ihre
            Nutzung der Website auszuwerten, um Reports über die
            Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um
            weitere mit der Websitenutzung und der Internetnutzung verbundene
            Dienstleistungen zu erbringen. Auch wird Google diese Informationen
            gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
            vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
            verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen
            Daten von Google in Verbindung bringen. Sie können die Installation
            der Cookies durch eine entsprechende Einstellung Ihrer Browser
            Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
            diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
            vollumfänglich nutzen können. Dieses Impressum wurde von ithelps.at
            zur Verfügung gestellt.  
          </p>
          <p>
            Durch die Nutzung dieser Website erklären Sie sich mit der
            Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor
            beschriebenen Art und Weise und zu dem zuvor benannten Zweck
            einverstanden. Der Datenerhebung und -speicherung kann jederzeit mit
            Wirkung für die Zukunft widersprochen werden. Angesichts der
            Diskussion um den Einsatz von Analysetools mit vollständigen
            IP-Adressen möchten wir darauf hinweisen, dass diese Website Google
            Analytics mit der Erweiterung &quot;_anonymizeIp()&quot; verwendet
            und daher IP-Adressen nur gekürzt weiterverarbeitet werden, um eine
            direkte Personenbeziehbarkeit auszuschließen
          </p>
        </Content>
      </div>
    </div>
  );
}

export default Policy;
