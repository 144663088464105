import React, { Component } from 'react';
import styles from 'pages/Profile/Profile.scss';
import Heading from 'components/Heading/Heading';
import Content from 'components/Content/Content';
import Icon from 'components/Icon/Icon';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { setUser } from 'pages/App/App.state';
import {
  baby,
  birdy,
  compi,
  dog,
  elifant,
  lemone,
  nilo,
  strauss,
  violetta,
} from 'components/Icon/list';
import Topbar from 'components/Topbar/Topbar';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

import { characters } from 'config';
import api from 'api';

class Profile extends Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      points: PropTypes.string,
    }),
  };

  static defaultProps = {
    user: {
      name: null,
      points: null,
      email: null,
    },
  };

  state = {
    message: '',
  };

  handleChange = name => {
    this.props.dispatch(setUser({ name }));
  };

  handleChangeEmail = email => {
    this.props.dispatch(setUser({ email }));
  };

  handleEraseAll = () => {
    if (
      confirm(
        'Bist du sicher, dass du deinen ganzen Fortschritt löschen willst?',
      )
    ) {
      try {
        localStorage.removeItem('state');
        window.location.replace('/');
      } catch (error) {
        // ignore
      }
    }
  };

  handleAddToHighscore = async () => {
    const {
      user: { userId, name, email, points, character, questsDone },
      token,
    } = this.props;

    // axios
    if (
      confirm(
        `Deine Email wird vertraulich behandelt und nicht mit Dritten geteilt. Wir senden dir nach Abgabe nur eine Email mit dem Zugang zu den Inhalten auf der Website.
        Dein Benutzername und die Punkteanzahl werden auf unserer Website veröffentlicht, drücke ok um fortzufahren.
      `,
      )
    ) {
      //
      // Prepare the content to send to server
      const highscoreValues = {
        userId,
        name,
        character,
        email,
        questsDone,
        token,
      };

      const response = await api
        .highscore()
        .update({
          ...highscoreValues,
        })
        .catch(error => {
          this.setState({
            message: 'Deine Informationen wurden nicht hochgeladen.',
          });
        });

      this.setState({ message: response.data });
    }
  };

  render() {
    const {
      user: { name, email, points, character },
    } = this.props;
    const { message } = this.state;

    if (!character) {
      return 'loading';
    }

    return (
      <div>
        <Topbar />
        <Heading>Profil</Heading>
        <div className={styles.container}>
          <div className={styles.character}>
            <Icon
              glyph={characters[character].icon}
              className={styles.characterIcon}
            />
          </div>
        </div>
        <br />
        <div className={styles.content}>
          <ul className={styles.list}>
            <li>Name: {name}</li>
            <li>Email: {email}</li>
            <li>Punkte: {points}</li>
          </ul>
          <hr className={styles.hr} />
          <Heading>Highscore</Heading>
          <p className={styles.message}>{message}</p>
          <fieldset className={styles.emailInput}>
            <label htmlFor="email">Email</label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={this.handleChangeEmail}
            />
          </fieldset>
          <Button
            view="outline"
            theme="info"
            className={styles.button}
            onClick={this.handleAddToHighscore}
          >
            in Highscore einfügen
          </Button>
          <Button view="outline" className={styles.button} to="/highscore">
            Highscore anzeigen
          </Button>
          <hr className={styles.hr} />
          <Heading>Urkunde</Heading>
          <Button disabled view="outline" className={styles.button}>
            Als Bild speichern
          </Button>
          <Button disabled view="outline" className={styles.button}>
            Mein Ergebnis teilen
          </Button>
          <hr className={styles.hr} />
          <Heading>App</Heading>
          <Button view="outline" className={styles.button} to="/impressum">
            Impressum
          </Button>
          <Button
            view="outline"
            className={styles.button}
            onClick={this.handleEraseAll}
          >
            Alles löschen
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ app: { user, token } }) => ({
  user,
  token,
});

export default withRouter(connect(mapStateToProps)(Profile));
