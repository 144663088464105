import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/Container/components/Row/Row.scss';

class Row extends Component {
  static propTypes = {
    children: PropTypes.node,
    centered: PropTypes.bool,
    reset: PropTypes.bool,
    className: PropTypes.string,
    padded: PropTypes.bool,
  };

  static defaultProps = {
    centered: false,
    className: '',
    reset: false,
    children: '',
    padded: false,
  };

  state = {};

  render() {
    const { children, className, centered, padded, reset } = this.props;
    return (
      <div
        className={classNames(
          styles.row,
          {
            [styles.reset]: reset,
            [styles.centered]: centered,
            [styles.padded]: padded,
          },
          className,
        )}
      >
        {children}
      </div>
    );
  }
}

export default Row;
