import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';
import { compose } from 'redux';
import Basemap from 'components/Map/Basemap';

class Map extends Component {
  internalMapRef = createRef();

  componentDidMount() {
    if (this.internalMapRef.current) {
      const googleMapInstance = this.internalMapRef.current.context[MAP];
      Basemap(googleMapInstance); // apply Basemap map tiles
    }
  }

  render() {
    const { children, mapRef, center, ...props } = this.props;
    return (
      <GoogleMap
        ref={map => {
          if (map) {
            mapRef.current = map;
            this.internalMapRef.current = map;
          }
        }}
        center={center}
        defaultZoom={14}
        defaultCenter={{ lat: 48.2221137, lng: 16.3905072 }}
        options={{
          gestureHandling: 'greedy',
          styles: [
            {
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'administrative.neighborhood',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }}
      >
        {children}
      </GoogleMap>
    );
  }
}

const enhance = compose(
  withScriptjs,
  withGoogleMap,
  withRouter,
);

export default enhance(Map);
