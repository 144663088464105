import React from 'react';
import { Link } from 'react-router-dom';
import { botanicQuestLogo, logoMa22, logoGmi } from 'components/Icon/list';
import Icon from 'components/Icon/Icon';
import Heading from 'components/Heading/Heading';
import Content from 'components/Content/Content';

import styles from 'components/NdQuestPublisher/NdQuestPublisher.scss';

export default function NdQuestPublisher() {
  return (
    <div>
      <Content center>
        App des Gregor Mendel Instituts und <br /> der MA22 der Stadt Wien{' '}
        <br />
        UMWELTSCHUTZ
      </Content>

      <div className={styles.imageWrapper}>
        <a
          href="https://www.gmi.oeaw.ac.at"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon glyph={logoGmi} className={styles.subLogo} />
        </a>
      </div>
      <div className={styles.imageWrapper}>
        <a
          href="https://www.wien.gv.at/kontakte/ma22/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon glyph={logoMa22} className={styles.subLogo} />
        </a>
      </div>
      <br />
      <br />
      <Heading tag="h2" center>
        Empfehlung
      </Heading>
      <Content center>
        Botanic Quest im Botanischen Garten <br /> der Universität Wien <br />{' '}
        beim Belvedere
      </Content>
      <div className={styles.imageWrapper}>
        <a
          href="http://www.botanicquest.at/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon glyph={botanicQuestLogo} className={styles.subLogo} />
        </a>
      </div>
      <div className={styles.legalLinks}>
        <Link className={styles.legalLink} to="/impressum">
          Impressum
        </Link>
        <Link className={styles.legalLink} to="/datenschutz">
          Datenschutz
        </Link>
      </div>
    </div>
  );
}
