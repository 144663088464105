import React, { Component } from 'react';
import classNames from 'classnames';

import styles from 'components/MonumentButton/MonumentButton.scss';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import { crosshair, checkmark } from '../Icon/list';

export default class MonumentButton extends Component {
  render() {
    const { children, onClick, id, completed } = this.props;
    return (
      <Link
        to={completed ? `/monuments` : `/map/${global.md5(id)}`}
        className={classNames(styles.container, {
          [styles.active]: completed,
        })}
      >
        <div className={styles.iconContainer}>
          <Icon
            glyph={completed ? checkmark : crosshair}
            className={styles.icon}
          />
        </div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </Link>
    );
  }
}
