exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Heading__small-HOzkpy,.Heading__h3-jCOkpU{font-size:22px;font-size:1.57143rem;font-weight:500}.Heading__medium-22YIvA,.Heading__h2-11dIaL{font-size:30px;font-size:2.14286rem}.Heading__large-3To9Y7,.Heading__h1-2aFWiJ{font-size:20px;font-size:1.42857rem;margin:10px}.Heading__h1-2aFWiJ{text-align:center}.Heading__h4-yHHXn8,.Heading__h5-2aVc5A,.Heading__h6-5YdVhy{font-size:12px;font-size:.85714rem;font-weight:300}.Heading__heading-2Szlnc{overflow:-webkit-paged-x;font-weight:400;line-height:1.1}.Heading__heading-2Szlnc h1,.Heading__heading-2Szlnc h2,.Heading__heading-2Szlnc h3,.Heading__heading-2Szlnc h4,.Heading__heading-2Szlnc h5,.Heading__heading-2Szlnc h6{margin:0;font-weight:400}.Heading__heading-2Szlnc.Heading__noPadding-2eXujC{padding:0}.Heading__center-1Pwfin{text-align:center}\n", ""]);

// Exports
exports.locals = {
	"small": "Heading__small-HOzkpy",
	"h3": "Heading__h3-jCOkpU",
	"medium": "Heading__medium-22YIvA",
	"h2": "Heading__h2-11dIaL",
	"large": "Heading__large-3To9Y7",
	"h1": "Heading__h1-2aFWiJ",
	"h4": "Heading__h4-yHHXn8",
	"h5": "Heading__h5-2aVc5A",
	"h6": "Heading__h6-5YdVhy",
	"heading": "Heading__heading-2Szlnc",
	"noPadding": "Heading__noPadding-2eXujC",
	"center": "Heading__center-1Pwfin"
};