import React, { Component } from 'react';
import { Marker } from 'react-google-maps';

const currentLocationSvg = `<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
<style type="text/css">
.st0{opacity:0.35;fill:#9FC75F;}
.st1{fill:#FFFFFF;}
.st2{fill:url(#SVGID_1_);}
</style>
<circle class="st0" cx="40" cy="40" r="40"/>
<path class="st1" d="M40,24c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S48.8,24,40,24z M40,51c-6.1,0-11-4.9-11-11s4.9-11,11-11
s11,4.9,11,11S46.1,51,40,51z"/>
<radialGradient id="SVGID_1_" cx="36.9147" cy="36.8611" r="13.125" gradientTransform="matrix(0.8381 0 0 0.8381 6.4762 6.5571)" gradientUnits="userSpaceOnUse">
<stop  offset="0" style="stop-color:#FFFFFF"/>
<stop  offset="0.6214" style="stop-color:#E7411D"/>
<stop  offset="0.6801" style="stop-color:#E03F1B"/>
<stop  offset="0.7634" style="stop-color:#CE3916"/>
<stop  offset="0.8613" style="stop-color:#AF2E0E"/>
<stop  offset="0.9695" style="stop-color:#852003"/>
<stop  offset="1" style="stop-color:#781C00"/>
</radialGradient>
<circle class="st2" cx="40" cy="40" r="11"/>
</svg>`;

const markerUrl = `data:image/svg+xml;charset=UTF-8;base64,${btoa(
  currentLocationSvg,
)}`;

export default class MapMarker extends Component {
  render() {
    const { options, ...props } = this.props;

    const icon = {
      url: markerUrl, // 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
      scaledSize: new window.google.maps.Size(50, 50),
    };

    return <Marker options={{ icon, ...options }} {...props} />;
  }
}
