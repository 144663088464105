exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Header__notification-1LGFAQ{position:fixed;z-index:99999;top:66px;left:0;display:flex;flex-direction:column-reverse;margin-bottom:0;margin-bottom:20px}.Header__notificationAlternative-2xgBYW{display:flex;flex-direction:column-reverse;margin-bottom:0;margin-bottom:20px}.Header__resend-1uLeWX{margin-left:20px}.Header__buttonWrapper-2hQhQE{text-align:right}.Header__notificationWrapper-1ymXd7{align-items:center;justify-content:center}\n", ""]);

// Exports
exports.locals = {
	"notification": "Header__notification-1LGFAQ",
	"notificationAlternative": "Header__notificationAlternative-2xgBYW",
	"resend": "Header__resend-1uLeWX",
	"buttonWrapper": "Header__buttonWrapper-2hQhQE",
	"notificationWrapper": "Header__notificationWrapper-1ymXd7"
};