exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Characters__container-3avTXm{height:100%;min-height:100vh;background:#3a5631;background:linear-gradient(90deg, #3a5631 0%, #6a582a 100%)}.Characters__heading-3LJHLW{color:#fff;opacity:0.8}.Characters__characters-7-LdY-{display:grid;padding:15px 30px 50px;grid-gap:15px;grid-template-columns:1fr 1fr;text-align:center}.Characters__character-1vHBGJ{padding:15px;background-color:white;border-radius:30px}\n", ""]);

// Exports
exports.locals = {
	"container": "Characters__container-3avTXm",
	"heading": "Characters__heading-3LJHLW",
	"characters": "Characters__characters-7-LdY-",
	"character": "Characters__character-1vHBGJ"
};