import logo from 'assets/icons/logo.svg';
import info from 'assets/icons/info.svg';
import acceptTick from 'assets/icons/accept_tick.svg';
import buttonArrow from 'assets/icons/button_arrow.svg';
import calendar from 'assets/icons/calendar.svg';
import crossExtended from 'assets/icons/cross_extended.svg';
import editPhoto from 'assets/icons/editphoto.svg';
import plus from 'assets/icons/plus.svg';
import menu from 'assets/icons/menu.svg';
import close from 'assets/icons/close.svg';
import logotree from 'assets/icons/logotree.svg';
import crosshair from 'assets/icons/crosshair.svg';
import wien from 'assets/icons/wien.svg';
import lupe from 'assets/icons/lupe.svg';
import circle from 'assets/icons/circle.svg';
import baby from 'assets/icons/characters/baby.svg';
import birdy from 'assets/icons/characters/birdy.svg';
import compi from 'assets/icons/characters/compi.svg';
import dog from 'assets/icons/characters/dog.svg';
import elifant from 'assets/icons/characters/elifant.svg';
import lemone from 'assets/icons/characters/lemone.svg';
import nilo from 'assets/icons/characters/nilo.svg';
import strauss from 'assets/icons/characters/strauss.svg';
import violetta from 'assets/icons/characters/violetta.svg';
import marker from 'assets/icons/marker.svg';
import question from 'assets/icons/question.svg';
import questionHover from 'assets/icons/questionHover.svg';
import checkmark from 'assets/icons/checkmark.svg';
import botanicQuestLogo from 'assets/icons/botanicquest-logo.svg';
import logoMa22 from 'assets/icons/logo_ma22.svg';
import logoGmi from 'assets/icons/logo_gmi.svg';

export {
  logo,
  info,
  close,
  acceptTick,
  menu,
  buttonArrow,
  calendar,
  crossExtended,
  editPhoto,
  logotree,
  plus,
  circle,
  crosshair,
  wien,
  lupe,
  baby,
  birdy,
  compi,
  dog,
  elifant,
  lemone,
  nilo,
  strauss,
  violetta,
  marker,
  question,
  checkmark,
  botanicQuestLogo,
  questionHover,
  logoMa22,
  logoGmi,
};
