/* eslint-disable */

export function createFingerprint() {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // Text with lowercase/uppercase/punctuation symbols
  const txt = 'ndquest,at <canvas> 1.0';
  ctx.textBaseline = 'top';
  // The most common type
  ctx.font = '14px Arial';
  ctx.textBaseline = 'alphabetic';
  ctx.fillStyle = '#f60';
  ctx.fillRect(125, 1, 62, 20);
  // Some tricks for color mixing to increase the difference in rendering
  ctx.fillStyle = '#069';
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
  ctx.fillText(txt, 4, 17);

  return global.md5(canvas.toDataURL());
}

export function debounce(a, b, c) {
  let d, e;
  return function() {
    function h() {
      (d = null), c || (e = a.apply(f, g));
    }
    var f = this,
      g = arguments;
    return (
      clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
    );
  };
}

export const shuffle = o => {
  let j;
  let x;
  let i;

  for (i = o.length; i; i -= 1) {
    j = Math.floor(Math.random() * i);
    x = o[i - 1];
    o[i - 1] = o[j];
    o[j] = x;
  }
  return o;
};

export const nl2br = str => {
  const breakTag = '<br/>';
  return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

const playNote = (frequency, startTime, duration, type) => {
  const osc1 = audioContext.createOscillator();
  const osc2 = audioContext.createOscillator();
  const volume = audioContext.createGain();

  // Set oscillator wave type
  osc1.type = type;
  osc2.type = type;

  volume.gain.value = 1;

  // Set up node routing
  osc1.connect(volume);
  osc2.connect(volume);
  volume.connect(audioContext.destination);

  // Detune oscillators for chorus effect
  osc1.frequency.value = frequency + 1;
  osc2.frequency.value = frequency - 2;

  // Fade out
  volume.gain.setValueAtTime(0.1, startTime + duration - 0.05);
  volume.gain.linearRampToValueAtTime(0, startTime + duration);

  // Start oscillators
  osc1.start(startTime);
  osc2.start(startTime);

  // Stop oscillators
  osc1.stop(startTime + duration);
  osc2.stop(startTime + duration);
};

export const successSound = () => {
  // Play a 'B' now that lasts for 0.116 seconds
  playNote(393.883, audioContext.currentTime, 0.116, 'triangle');

  // Play an 'E' just as the previous note finishes, that lasts for 0.232 seconds
  playNote(493.255, audioContext.currentTime + 0.116, 0.232, 'triangle');
};

export const errorSound = () => {
  // Play a 'B' now that lasts for 0.180 seconds
  playNote(42.883, audioContext.currentTime, 0.18, 'square');

  // Play an 'E' just as the previous note finishes, that lasts for 0.232 seconds
  playNote(22.255, audioContext.currentTime + 0.116, 0.232, 'square');
};

//
// encode / decode string in an easy way
//
export const enc = str => {
  let encoded = '';

  for (let i = 0; i < str.length; i++) {
    const a = str.charCodeAt(i);
    const b = a ^ 9; // bitwise XOR with any number, e.g. 123
    encoded += String.fromCharCode(b);
  }

  return encoded;
};

//
// create Object from decoded token
//
export const qd = str => {
  // object to return
  const qf = {};

  // get the relevant part
  const arr = str.split(': ');

  // get array of quests
  const q = arr[1].split('-');

  if (q[0].length) {
    // loop through quests
    for (let i = 0; i < q.length; i++) {
      // get the QuestID and its points
      const t = q[i].split('/');

      // set the object
      qf[t[0]] = t[1];
    }
  }

  // return the object
  return qf;
};

//
// create token from object
//
export const qe = obj => {
  // start of string
  const c = ['i', 'a', 0, 'l', 'm', 's', ' ', ':', 'u'];
  const st = c[4] + c[1] + c[0] + c[3] + c[6] + c[8] + c[5] + c[7] + c[6];

  // string to return
  let str = '';

  // loop through object
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      str += `${property}/${obj[property]}`;
      str += '-';
    }
  }

  // remove last minus if anything isset
  if (str.length) {
    str = str.slice(0, -1);
  }

  // return start and string
  return st + str;
};

/*
 * http://www.myersdaily.org/joseph/javascript/md5-text.html
 */
/* this function is much faster,
  so if possible we use it. Some IEs
  are the only ones I know of that
  need the idiotic second function,
  generated by an if clause.  */

let add32 = (a, b) => (a + b) & 0xffffffff;

const cmn = (q, a, b, x, s, t) => {
  a = add32(add32(a, q), add32(x, t));
  return add32((a << s) | (a >>> (32 - s)), b);
};

const ff = (a, b, c, d, x, s, t) => cmn((b & c) | (~b & d), a, b, x, s, t);
const gg = (a, b, c, d, x, s, t) => cmn((b & d) | (c & ~d), a, b, x, s, t);
const hh = (a, b, c, d, x, s, t) => cmn(b ^ c ^ d, a, b, x, s, t);
const ii = (a, b, c, d, x, s, t) => cmn(c ^ (b | ~d), a, b, x, s, t);

const md5cycle = (x, k) => {
  let a = x[0];
  let b = x[1];
  let c = x[2];
  let d = x[3];

  a = ff(a, b, c, d, k[0], 7, -680876936);
  d = ff(d, a, b, c, k[1], 12, -389564586);
  c = ff(c, d, a, b, k[2], 17, 606105819);
  b = ff(b, c, d, a, k[3], 22, -1044525330);
  a = ff(a, b, c, d, k[4], 7, -176418897);
  d = ff(d, a, b, c, k[5], 12, 1200080426);
  c = ff(c, d, a, b, k[6], 17, -1473231341);
  b = ff(b, c, d, a, k[7], 22, -45705983);
  a = ff(a, b, c, d, k[8], 7, 1770035416);
  d = ff(d, a, b, c, k[9], 12, -1958414417);
  c = ff(c, d, a, b, k[10], 17, -42063);
  b = ff(b, c, d, a, k[11], 22, -1990404162);
  a = ff(a, b, c, d, k[12], 7, 1804603682);
  d = ff(d, a, b, c, k[13], 12, -40341101);
  c = ff(c, d, a, b, k[14], 17, -1502002290);
  b = ff(b, c, d, a, k[15], 22, 1236535329);

  a = gg(a, b, c, d, k[1], 5, -165796510);
  d = gg(d, a, b, c, k[6], 9, -1069501632);
  c = gg(c, d, a, b, k[11], 14, 643717713);
  b = gg(b, c, d, a, k[0], 20, -373897302);
  a = gg(a, b, c, d, k[5], 5, -701558691);
  d = gg(d, a, b, c, k[10], 9, 38016083);
  c = gg(c, d, a, b, k[15], 14, -660478335);
  b = gg(b, c, d, a, k[4], 20, -405537848);
  a = gg(a, b, c, d, k[9], 5, 568446438);
  d = gg(d, a, b, c, k[14], 9, -1019803690);
  c = gg(c, d, a, b, k[3], 14, -187363961);
  b = gg(b, c, d, a, k[8], 20, 1163531501);
  a = gg(a, b, c, d, k[13], 5, -1444681467);
  d = gg(d, a, b, c, k[2], 9, -51403784);
  c = gg(c, d, a, b, k[7], 14, 1735328473);
  b = gg(b, c, d, a, k[12], 20, -1926607734);

  a = hh(a, b, c, d, k[5], 4, -378558);
  d = hh(d, a, b, c, k[8], 11, -2022574463);
  c = hh(c, d, a, b, k[11], 16, 1839030562);
  b = hh(b, c, d, a, k[14], 23, -35309556);
  a = hh(a, b, c, d, k[1], 4, -1530992060);
  d = hh(d, a, b, c, k[4], 11, 1272893353);
  c = hh(c, d, a, b, k[7], 16, -155497632);
  b = hh(b, c, d, a, k[10], 23, -1094730640);
  a = hh(a, b, c, d, k[13], 4, 681279174);
  d = hh(d, a, b, c, k[0], 11, -358537222);
  c = hh(c, d, a, b, k[3], 16, -722521979);
  b = hh(b, c, d, a, k[6], 23, 76029189);
  a = hh(a, b, c, d, k[9], 4, -640364487);
  d = hh(d, a, b, c, k[12], 11, -421815835);
  c = hh(c, d, a, b, k[15], 16, 530742520);
  b = hh(b, c, d, a, k[2], 23, -995338651);

  a = ii(a, b, c, d, k[0], 6, -198630844);
  d = ii(d, a, b, c, k[7], 10, 1126891415);
  c = ii(c, d, a, b, k[14], 15, -1416354905);
  b = ii(b, c, d, a, k[5], 21, -57434055);
  a = ii(a, b, c, d, k[12], 6, 1700485571);
  d = ii(d, a, b, c, k[3], 10, -1894986606);
  c = ii(c, d, a, b, k[10], 15, -1051523);
  b = ii(b, c, d, a, k[1], 21, -2054922799);
  a = ii(a, b, c, d, k[8], 6, 1873313359);
  d = ii(d, a, b, c, k[15], 10, -30611744);
  c = ii(c, d, a, b, k[6], 15, -1560198380);
  b = ii(b, c, d, a, k[13], 21, 1309151649);
  a = ii(a, b, c, d, k[4], 6, -145523070);
  d = ii(d, a, b, c, k[11], 10, -1120210379);
  c = ii(c, d, a, b, k[2], 15, 718787259);
  b = ii(b, c, d, a, k[9], 21, -343485551);

  x[0] = add32(a, x[0]);
  x[1] = add32(b, x[1]);
  x[2] = add32(c, x[2]);
  x[3] = add32(d, x[3]);
};

function md5blk(s) {
  /* I figured global was faster.   */
  const md5blks = [];
  let i;

  for (i = 0; i < 64; i += 4) {
    md5blks[i >> 2] =
      s.charCodeAt(i) +
      (s.charCodeAt(i + 1) << 8) +
      (s.charCodeAt(i + 2) << 16) +
      (s.charCodeAt(i + 3) << 24);
  }
  return md5blks;
}

const md51 = s => {
  const n = s.length;
  const state = [1732584193, -271733879, -1732584194, 271733878];
  let i;

  for (i = 64; i <= s.length; i += 64) {
    md5cycle(state, md5blk(s.substring(i - 64, i)));
  }
  s = s.substring(i - 64);
  const tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (i = 0; i < s.length; i++) {
    tail[i >> 2] |= s.charCodeAt(i) << (i % 4 << 3);
  }
  tail[i >> 2] |= 0x80 << (i % 4 << 3);
  if (i > 55) {
    md5cycle(state, tail);
    for (i = 0; i < 16; i++) {
      tail[i] = 0;
    }
  }
  tail[14] = n * 8;
  md5cycle(state, tail);
  return state;
};

/* there needs to be support for Unicode here,
 * unless we pretend that we can redefine the MD-5
 * algorithm for multi-byte characters (perhaps
 * by adding every four 16-bit characters and
 * shortening the sum to 32 bits). Otherwise
 * I suggest performing MD-5 as if every character
 * was two bytes--e.g., 0040 0025 = @%--but then
 * how will an ordinary MD-5 sum be matched?
 * There is no way to standardize text to something
 * like UTF-8 before transformation; speed cost is
 * utterly prohibitive. The JavaScript standard
 * itself needs to look at this: it should start
 * providing access to strings as preformed UTF-8
 * 8-bit unsigned value arrays.
 */

const hexChr = '0123456789abcdef'.split('');

const rhex = n => {
  let s = '';
  let j = 0;
  for (; j < 4; j++) {
    s += hexChr[(n >> (j * 8 + 4)) & 0x0f] + hexChr[(n >> (j * 8)) & 0x0f];
  }
  return s;
};

function hex(x) {
  for (let i = 0; i < x.length; i++) {
    x[i] = rhex(x[i]);
  }
  return x.join('');
}

global.md5 = s => hex(md51(s));

if (global.md5('hello') !== '5d41402abc4b2a76b9719d911017c592') {
  add32 = (x, y) => {
    const lsw = (x & 0xffff) + (y & 0xffff);
    const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xffff);
  };
}

/* eslint-enable */
