import React from 'react';

import Button from 'components/Button/Button';

import styles from 'components/Banner/Banner.scss';

export function addGtagToDom() {
  const scriptTag = document.createElement('script');
  scriptTag.id = 'gtScript1';
  scriptTag.async = true;
  scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-6CVHN6KJKN';

  const gTag = document.createElement('script');
  gTag.id = 'gtScript2';
  gTag.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
   `;

  document.head.appendChild(scriptTag);
  document.head.appendChild(gTag);

  window.localStorage.setItem('cc-ndqust', 'accepted');
}

export function removeGtagFromDom() {
  window.localStorage.setItem('cc-ndqust', 'rejected');

  const scriptTag1 = document.getElementById('gtScript1');
  scriptTag1.parentElement.removeChild(scriptTag1);
  const scriptTag2 = document.getElementById('gtScript2');
  scriptTag2.parentElement.removeChild(scriptTag2);

  window.localStorage.setItem('cc-ndqust', 'rejected');
}

const Banner = () => {
  const [state, setState] = React.useState(
    () => localStorage.getItem('cc-ndqust') || 'initial',
  );

  React.useEffect(() => {
    window.localStorage.setItem('cc-ndqust', state);

    if (state === 'accepted') {
      addGtagToDom();
    }
  }, [state]);

  if (state !== 'initial') return null;

  return (
    <div className={styles.container}>
      <p>
        Diese Website verwendet Cookies & google-Analytics um die Seite für Sie
        optimal zu gestalten und um diese fortlaufend zu verbessern. Durch die
        weitere Nutzung der Website stimmen Sie der Verwendung zu.
      </p>
      <div>
        <Button
          view="outline"
          theme="danger"
          className={styles.button}
          onClick={() => setState('rejected')}
        >
          Schließen
        </Button>
        <Button view="outline" onClick={() => setState('accepted')}>
          Akzeptieren
        </Button>
      </div>
    </div>
  );
};
export default Banner;
