import {
  baby,
  birdy,
  compi,
  dog,
  elifant,
  lemone,
  nilo,
  strauss,
  violetta,
} from 'components/Icon/list';

export const medalPoints = 300;
export const characters = {
  baby: {
    icon: baby,
  },
  birdy: {
    icon: birdy,
  },
  compi: {
    icon: compi,
  },
  dog: {
    icon: dog,
  },
  elifant: {
    icon: elifant,
  },
  lemone: {
    icon: lemone,
  },
  nilo: {
    icon: nilo,
  },
  strauss: {
    icon: strauss,
  },
  violetta: {
    icon: violetta,
  },
};
