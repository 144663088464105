exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Column__column-2nmiXQ{padding:0 15px}.Column__half-vMqYKk{width:100%}@media (min-width: 768px){.Column__half-vMqYKk{width:50%}}.Column__third-2_G211{width:100%}@media (min-width: 768px){.Column__third-2_G211{width:33.3%}}.Column__twothird-2x6J3t{width:100%}@media (min-width: 768px){.Column__twothird-2x6J3t{width:66.6%}}.Column__quarter-5xyGRB{width:100%}@media (min-width: 768px){.Column__quarter-5xyGRB{width:25%}}.Column__twoquarter-3_TpEf{width:100%}@media (min-width: 768px){.Column__twoquarter-3_TpEf{width:50%}}.Column__threequarter-jXYuPu{width:100%}@media (min-width: 768px){.Column__threequarter-jXYuPu{width:75%}}@media (min-width: 768px){.Column__fill-3fwhjP{flex:1}}.Column__full-2Hd5_9{width:100%}\n", ""]);

// Exports
exports.locals = {
	"column": "Column__column-2nmiXQ",
	"half": "Column__half-vMqYKk",
	"third": "Column__third-2_G211",
	"twothird": "Column__twothird-2x6J3t",
	"quarter": "Column__quarter-5xyGRB",
	"twoquarter": "Column__twoquarter-3_TpEf",
	"threequarter": "Column__threequarter-jXYuPu",
	"fill": "Column__fill-3fwhjP",
	"full": "Column__full-2Hd5_9"
};