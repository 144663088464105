import React, { Component } from 'react';
import Heading from 'components/Heading/Heading';
import Topbar from 'components/Topbar/Topbar';

import styles from 'pages/Impressum/Impressum.scss';
import NdQuestPublisher from 'components/NdQuestPublisher/NdQuestPublisher';

export default class Impressum extends Component {
  render() {
    return (
      <div>
        <Topbar />
        <div className={styles.container}>
          <Heading tag="h2" className={styles.heading}>
            Impressum
          </Heading>
          <NdQuestPublisher />
        </div>
      </div>
    );
  }
}
