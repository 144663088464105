import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash-es/isEqual';

import MapComponent from 'components/Map/Map';
import MapMarker from 'components/MapMarker/MapMarker';
import CurrentLocationMarker from 'components/CurrentLocationMarker/CurrentLocationMarker';

import mapPlaceholder from 'assets/images/mapPlaceholder.png';

import styles from 'pages/Map/Map.scss';

const locationOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

class Map extends Component {
  state = {
    currentLocation: null,
    currentCordinates: null,
    mapRef: createRef,
    shortcode: '',
  };

  componentDidMount() {
    if ('geolocation' in navigator) {
      this.getCurrentLocation();
      this.watchCurrentLocation();
    } else {
      alert(
        'NDQuest kann deine aktuelle Position nicht auf der Karte anzeigen',
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { quests, match } = this.props;
    const { currentLocation } = this.state;

    if (!isEqual(quests, prevProps.quests)) {
      this.fitBounds(currentLocation);
    }

    if (!isEqual(currentLocation, prevState.currentLocation)) {
      this.fitBounds(currentLocation);
    }

    if (!isEqual(match.params, prevProps.match.params)) {
      const shortcodeMd5 = match.params.id;

      if (shortcodeMd5) {
        const shortcode = Object.keys(quests).find(
          shortcode => global.md5(shortcode) === shortcodeMd5,
        );
        const quest = quests[shortcode];
        if (quest) {
          this.setState({ shortcode });
          const markerCoordinates = {
            latitude: quest.latx,
            longitude: quest.lony,
          };
          this.fitBounds(currentLocation, markerCoordinates);
        }
      } else {
        this.fitBounds(currentLocation);
      }
    }
  }

  componentWillUnmount() {
    if ('geolocation' in navigator) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }

  getCurrentLocation = () => {
    const { quests, match } = this.props;

    const success = position => {
      const coordinates = position.coords;
      this.setState(
        {
          currentLocation: {
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          },
        },
        () => {
          // because of window.google
          setTimeout(() => {
            const shortcodeMd5 = match.params.id;
            if (shortcodeMd5) {
              const shortcode = Object.keys(quests).find(
                shortcode => global.md5(shortcode) === shortcodeMd5,
              );
              const quest = quests[shortcode];

              if (quest) {
                this.setState({ shortcode });
                const markerCoordinates = {
                  latitude: quest.latx,
                  longitude: quest.lony,
                };
                this.fitBounds(coordinates, markerCoordinates);
              }
            } else {
              this.setState({ shortcode: '' });
              this.centerMap(coordinates);
              this.fitBounds(coordinates);
            }
          }, 500);
        },
      );
    };

    const error = error => {
      alert(
        'NDQuest kann deine aktuelle Position nicht auf der Karte anzeigen',
      );
    };

    navigator.geolocation.getCurrentPosition(success, error, locationOptions);
  };

  watchCurrentLocation = () => {
    const success = position => {
      const coordinates = position.coords;
      this.setState(
        {
          currentLocation: {
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          },
        },
        () => {
          setTimeout(() => {
            this.centerMap(coordinates);
            this.fitBounds(coordinates);
          }, 500);
        },
      );
    };

    const error = error => {
      // fail silently
    };

    this.watchId = navigator.geolocation.watchPosition(
      success,
      error,
      locationOptions,
    );
  };

  centerMap = currentLocation => {
    if (!window.google || !currentLocation) {
      return;
    }

    const googleLocation = new window.google.maps.LatLng(
      currentLocation.latitude,
      currentLocation.longitude,
    );

    this.setState({
      currentCordinates: googleLocation,
    });
  };

  fitBounds = (currentLocation, markerCoordinate) => {
    const { quests } = this.props;
    const { mapRef } = this.state;

    if (!quests || !window.google) return;

    const bounds = new window.google.maps.LatLngBounds();

    if (currentLocation) {
      // create google LatLng object from currentLocation
      const currentLocationGoogle = new window.google.maps.LatLng(
        currentLocation.latitude,
        currentLocation.longitude,
      );
      bounds.extend(currentLocationGoogle);
    }

    if (markerCoordinate) {
      const markerCordinateGoogle = new window.google.maps.LatLng(
        markerCoordinate.latitude,
        markerCoordinate.longitude,
      );
      bounds.extend(markerCordinateGoogle);
    } else {
      // map over all quests
      const coordinates = Object.values(quests).map(
        ({ shortcode, latx, lony }) => {
          const latLng = new window.google.maps.LatLng(latx, lony);
          bounds.extend(latLng);
          return latLng;
        },
      );
    }

    mapRef.current.fitBounds(bounds);
  };

  render() {
    const { quests, match } = this.props;
    const { currentLocation, currentCordinates, mapRef } = this.state;

    return (
      <div className={styles.container}>
        <MapComponent
          mapRef={mapRef}
          center={currentCordinates || { lat: 48.20849, lng: 16.37208 }}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDUoWc_TPDQ_rAqFF4ZOw7UwE7EU0cw670&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={
            <div
              style={{
                height: `calc(100vh - 74px)`,
                width: '100%',
                backgroundImage: `url(${mapPlaceholder})`,
                backgroundSize: 'cover',
              }}
            />
          }
          containerElement={
            <div
              style={{
                height: `calc(100vh - 74px)`,
                width: '100%',
                backgroundImage: `url(${mapPlaceholder})`,
                backgroundSize: 'cover',
              }}
            />
          }
          mapElement={
            <div
              style={{
                height: `calc(100vh - 74px)`,
                width: '100%',
              }}
            />
          }
        >
          {currentLocation && (
            <CurrentLocationMarker
              position={{
                lat: currentLocation.latitude,
                lng: currentLocation.longitude,
              }}
            />
          )}
          {quests &&
            Object.values(quests).map(({ shortcode, latx, lony }) => (
              <MapMarker
                selected={this.state.shortcode === shortcode}
                completed={this.props.user.completedQuests.includes(shortcode)}
                key={shortcode}
                position={{ lat: parseFloat(latx), lng: parseFloat(lony) }}
                onClick={() =>
                  this.props.history.push(`/monuments/${global.md5(shortcode)}`)
                }
              />
            ))}
        </MapComponent>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user, quests } }) => ({
  user,
  quests,
});

export default connect(mapStateToProps)(Map);
