exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ViennaMap__mapIconWrapper-3cUAY6{display:flex;flex-direction:column;align-items:center}.ViennaMap__wienIcon-2v5T5E{width:100%;height:300px}.ViennaMap__lupeIcon-3_M2YD{height:40px}.ViennaMap__active-BTmJ_z{margin:100px 0;transform:scale(1.4)}\n", ""]);

// Exports
exports.locals = {
	"mapIconWrapper": "ViennaMap__mapIconWrapper-3cUAY6",
	"wienIcon": "ViennaMap__wienIcon-2v5T5E",
	"lupeIcon": "ViennaMap__lupeIcon-3_M2YD",
	"active": "ViennaMap__active-BTmJ_z"
};