import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from 'components/QuestAnswer/QuestAnswer.scss';

export default function QuestAnswer(props) {
  const {
    onClick,
    children,
    correct,
    onCorrectAnswer,
    onInvalidAnswer,
  } = props;

  const [show, setShow] = useState(false);
  let timeout = null;

  const handleOnClick = () => {
    if (correct) {
      onCorrectAnswer();
    } else {
      onInvalidAnswer();
    }
    // animate
    setShow(true);
    timeout = setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  useEffect(() => () => {
    if (show) {
      clearTimeout(timeout);
    }
  });

  return (
    <div className={styles.answer} onClick={handleOnClick}>
      <div
        className={classNames(styles.bullet, {
          [styles.correct]: show && correct,
          [styles.wrong]: show && !correct,
        })}
      />
      <div className={styles.answerText}>{children}</div>
    </div>
  );
}
