exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".QuestQuestion__container-hBBFz8{min-height:100vh;background:#3a5631;background:linear-gradient(90deg, #3a5631 0%, #6a582a 100%)}.QuestQuestion__header-2EKVjz{position:relative;padding:5px 0;color:#fff}.QuestQuestion__heading-298kKd{position:relative;z-index:2;color:#fff}.QuestQuestion__answers-12UcDU{display:flex;flex-direction:column;justify-content:center;padding:20px}.QuestQuestion__answer-1qFZhL{display:flex;align-items:center;justify-content:center;margin:10px 0;color:#fff}.QuestQuestion__bullet-1ho6PX{width:50px;height:50px;background:#95a08a;border-radius:25px;box-shadow:0 14px 28px rgba(0,0,0,0.25),0 10px 10px rgba(0,0,0,0.22)}.QuestQuestion__answerText-2MQFrW{margin-left:70px;font-size:20px}.QuestQuestion__progressContainer-3irHBI{position:absolute;z-index:1;top:0;right:0;left:0;height:100%}.QuestQuestion__progress-iEMk3D{height:100%;background-color:#292c30;transition:width 0.2s ease-in}\n", ""]);

// Exports
exports.locals = {
	"container": "QuestQuestion__container-hBBFz8",
	"header": "QuestQuestion__header-2EKVjz",
	"heading": "QuestQuestion__heading-298kKd",
	"answers": "QuestQuestion__answers-12UcDU",
	"answer": "QuestQuestion__answer-1qFZhL",
	"bullet": "QuestQuestion__bullet-1ho6PX",
	"answerText": "QuestQuestion__answerText-2MQFrW",
	"progressContainer": "QuestQuestion__progressContainer-3irHBI",
	"progress": "QuestQuestion__progress-iEMk3D"
};