import React, { Component } from 'react';
import { connect } from 'react-redux';

import MonumentButton from 'components/MonumentButton/MonumentButton';
import ViennaMap from 'components/ViennaMap/ViennaMap';
import Heading from 'components/Heading/Heading';
import Topbar from 'components/Topbar/Topbar';
import Content from 'components/Content/Content';

import styles from 'pages/Monuments/Monuments.scss';

const compareAddress = (a, b) =>
  (a.address === null) - (b.address === null) ||
  +(a.address > b.address) ||
  -(a.address < b.address);

class Monuments extends Component {
  componentDidMount() {}

  render() {
    const { quests, user } = this.props;

    return (
      <div className={styles.container}>
        <Topbar goHome />
        <div>
          <Heading center tag="h2">
            Orte / Naturdenkmäler
          </Heading>
          <Content center>
            Derzeit enthält die App 17 Naturdenkmäler. Die Navigation dorthin
            übernimmt Google Maps.
          </Content>
        </div>
        <div className={styles.monumentsList}>
          {quests ? (
            Object.values(quests)
              .sort(compareAddress)
              .map(button => (
                <MonumentButton
                  completed={user.completedQuests.includes(button.shortcode)}
                  key={button.shortcode}
                  id={button.shortcode}
                >
                  {button.address}
                </MonumentButton>
              ))
          ) : (
            <h2 className={styles.noQuests}>
              Die Quests konnten leider nicht geladen werden!
            </h2>
          )}
        </div>
        <ViennaMap />
        <Content center className={styles.bottomText}>
          Den Weg zeigt dir der Button <span>"Wo".</span>
          <br />
          Geo-Lokalisierung (Googlemaps)
        </Content>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user, quests } }) => ({
  user,
  quests,
});

export default connect(mapStateToProps)(Monuments);
