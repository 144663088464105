import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/Button/Button.scss';

class Button extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    /** Text or element */
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    /** indicates if Button is full-width */
    wide: PropTypes.bool,
    rounded: PropTypes.bool,
    loading: PropTypes.bool,
    form: PropTypes.string,
    view: PropTypes.oneOf(['button', 'outline', 'link']),
    type: PropTypes.oneOf(['submit', 'reset', 'button', 'menu']),
    theme: PropTypes.oneOf([
      'default',
      'primary',
      'success',
      'danger',
      'info',
      'warning',
    ]),
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    /** SyntheticEvent * */
    onClick: PropTypes.func,
    to: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    form: null,
    id: null,
    type: null,
    className: '',
    theme: 'default',
    view: 'button',
    size: 'normal',
    wide: false,
    rounded: true,
    loading: false,
    disabled: false,
    to: null,
  };

  render() {
    const {
      id,
      type,
      form,
      disabled,
      theme,
      size,
      wide,
      rounded,
      children,
      view,
      loading,
      className,
      onClick,
      to,
    } = this.props;
    const classes = classNames(
      styles.container,
      styles[theme],
      styles[view],
      styles[size],
      {
        [styles.wide]: wide,
        [styles.rounded]: rounded,
        [styles.pending]: loading,
      },
      className,
    );

    const Tag = to ? Link : 'button';
    return (
      <Tag
        id={id}
        className={classes}
        type={type}
        form={form}
        disabled={disabled || loading}
        onClick={onClick}
        to={to}
      >
        {children}
      </Tag>
    );
  }
}

export default Button;
