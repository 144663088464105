import React, { useEffect, useState, useReducer } from 'react';
import Heading from 'components/Heading/Heading';
import Button from 'components/Button/Button';
import api from 'api';

import styles from 'pages/Highscore/Highscore.scss';

function Highscore() {
  const [highscore, setHighscore] = useState({
    loading: true,
    error: '',
    players: [],
  });

  useEffect(() => {
    api
      .highscore()
      .all()
      .then(response =>
        setHighscore({ ...highscore, players: response.data, loading: false }),
      )
      .catch(error =>
        setHighscore({
          ...highscore,
          error: 'Konnte den Highscore nicht laden',
          loading: false,
        }),
      );
  }, []);

  const players = highscore.players.map((player, rank) => (
    <tr key={player.name + player.points}>
      <td>{rank + 1}</td>
      <td>{player.name}</td>
      <td>{player.points}</td>
    </tr>
  ));

  return (
    <div className={styles.container}>
      <Heading tag="h1">Highscore</Heading>

      <p className={styles.subtitle}>
        Hier findest du den Highscore der letzten 7 Tage.
      </p>
      <p className={styles.error}>{highscore.error}</p>

      {highscore.loading ? (
        <p className={styles.loading}> Lädt...</p>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Punkte</th>
            </tr>
          </thead>
          <tbody>{players}</tbody>
        </table>
      )}

      <Button wide to="/profile">
        Zurück
      </Button>
    </div>
  );
}

export default Highscore;
