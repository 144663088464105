exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CorrectAnswer__container-3ycLr-{min-height:100vh;background:#3a5631;background:linear-gradient(90deg, #3a5631 0%, #6a582a 100%)}.CorrectAnswer__content-fPQISJ{display:grid;min-height:calc(100vh - 75px - 70px);align-items:center;justify-content:center;grid-gap:20px;grid-template-rows:auto 1fr auto}.CorrectAnswer__heading-PCt42y{color:#fff;font-size:1rem;letter-spacing:1px;opacity:0.7;text-align:center}.CorrectAnswer__correctAnswer-1kwCdK{color:#9fc75f;font-size:2rem;font-weight:bold;text-align:center}.CorrectAnswer__buttonWrapper-1Mnv_T{display:flex;flex-direction:column;align-items:center;justify-content:center;margin:0 auto}.CorrectAnswer__hintText-2T-4J3{color:#fff;font-size:1rem;letter-spacing:1px;text-align:center}.CorrectAnswer__hintText-2T-4J3 span{opacity:0.8}.CorrectAnswer__hintText-2T-4J3 strong{opacity:1}.CorrectAnswer__medalPoints-Zstnhz{color:#fff;font-size:18px;opacity:0.8;text-align:center}.CorrectAnswer__characterIcon-36doiR{width:220px;margin:10px 0;margin-bottom:20px}.CorrectAnswer__gameFinished-2UimKA{color:#fff;font-size:18px;opacity:0.8;text-align:center}.CorrectAnswer__thanksForPlaying-3z-g6d{margin:20px 0;font-size:20px;font-weight:bold;text-align:center}\n", ""]);

// Exports
exports.locals = {
	"container": "CorrectAnswer__container-3ycLr-",
	"content": "CorrectAnswer__content-fPQISJ",
	"heading": "CorrectAnswer__heading-PCt42y",
	"correctAnswer": "CorrectAnswer__correctAnswer-1kwCdK",
	"buttonWrapper": "CorrectAnswer__buttonWrapper-1Mnv_T",
	"hintText": "CorrectAnswer__hintText-2T-4J3",
	"medalPoints": "CorrectAnswer__medalPoints-Zstnhz",
	"characterIcon": "CorrectAnswer__characterIcon-36doiR",
	"gameFinished": "CorrectAnswer__gameFinished-2UimKA",
	"thanksForPlaying": "CorrectAnswer__thanksForPlaying-3z-g6d"
};