exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NavigationMenu__container-B5Ya9Z{width:100%}.NavigationMenu__wrapper-1cvcId{display:grid;padding:0;margin:0;background-color:#32303b;grid-gap:0;grid-template-columns:1fr 1fr 1fr}.NavigationMenu__text-1YRi7P{z-index:99;margin-top:-11px}.NavigationMenu__redText-2JFr2O{color:#d73826 !important}.NavigationMenu__item-15GH9K{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;padding-bottom:10px;background-color:#32303b;color:#9fc75f;letter-spacing:1px;text-transform:uppercase}.NavigationMenu__item-15GH9K:hover{color:#fff}.NavigationMenu__item-15GH9K::before{position:absolute;z-index:0;top:-13px;left:0;width:100%;height:14px;border-bottom:14px solid #32303b;content:''}.NavigationMenu__item-15GH9K:first-of-type::before{border-right:14px solid transparent}.NavigationMenu__item-15GH9K:nth-of-type(2)::before{border-right:14px solid transparent;border-left:14px solid transparent}.NavigationMenu__item-15GH9K:nth-of-type(3)::before{border-left:14px solid transparent}.NavigationMenu__icon-2i4_s1{height:40px}.NavigationMenu__character-32QO-q{position:relative;display:flex;width:100%;height:100%;align-items:center;justify-content:center}.NavigationMenu__score-3vSzEX{position:absolute;bottom:-12px;color:white;font-size:25px;font-weight:bold}.NavigationMenu__characterIcon-TNTo8e{width:48px;height:48px}.NavigationMenu__pseudoCircle-2Np3un{width:48px;height:48px;background-color:red;border-radius:50%}\n", ""]);

// Exports
exports.locals = {
	"container": "NavigationMenu__container-B5Ya9Z",
	"wrapper": "NavigationMenu__wrapper-1cvcId",
	"text": "NavigationMenu__text-1YRi7P",
	"redText": "NavigationMenu__redText-2JFr2O",
	"item": "NavigationMenu__item-15GH9K",
	"icon": "NavigationMenu__icon-2i4_s1",
	"character": "NavigationMenu__character-32QO-q",
	"score": "NavigationMenu__score-3vSzEX",
	"characterIcon": "NavigationMenu__characterIcon-TNTo8e",
	"pseudoCircle": "NavigationMenu__pseudoCircle-2Np3un"
};