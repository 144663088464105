import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from 'components/Content/Content.scss';

class Content extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    withParser: PropTypes.bool,
    isSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool,
    isResponsive: PropTypes.bool,
    center: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    isSmall: false,
    isMedium: true,
    isLarge: false,
    withParser: false,
    isResponsive: false,
    center: false,
    className: null,
  };

  render() {
    const {
      withParser,
      children,
      isSmall,
      isMedium,
      isLarge,
      isResponsive,
      center,
      className,
    } = this.props;
    return (
      <div
        className={classNames(styles.content, {
          [styles.isSmall]: isSmall,
          [styles.isMedium]: isMedium,
          [styles.isLarge]: isLarge,
          [styles.isResponsive]: isResponsive,
          [styles.center]: center,
          [className]: className,
        })}
        /* eslint-disable react/no-danger */
        dangerouslySetInnerHTML={
          withParser ? { __html: withParser && children } : null
        }
      >
        {!withParser ? children : null}
      </div>
    );
  }
}

export default Content;
