exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Highscore__container-YOgT4M{padding:10px}.Highscore__subtitle-hoDWym{padding:20px;text-align:center}.Highscore__error-3v00AO{color:tomato;font-size:14px}.Highscore__loading-3f2CcD{font-size:28px;text-align:center}.Highscore__table-3HVhIB{width:100%;margin-bottom:1rem;border-collapse:collapse;border-radius:0;border-spacing:0}.Highscore__table-3HVhIB tr{display:table-row;border-color:inherit;vertical-align:inherit}.Highscore__table-3HVhIB tfoot,.Highscore__table-3HVhIB thead{background:#f8f8f8;color:#0a0a0a}.Highscore__table-3HVhIB tbody,.Highscore__table-3HVhIB tfoot,.Highscore__table-3HVhIB thead{border:1px solid #f1f1f1;background-color:#fefefe}.Highscore__table-3HVhIB tfoot td,.Highscore__table-3HVhIB tfoot th,.Highscore__table-3HVhIB thead td,.Highscore__table-3HVhIB thead th{padding:0.5rem 0.625rem 0.625rem;font-weight:700;text-align:left}.Highscore__table-3HVhIB tbody td,.Highscore__table-3HVhIB tbody th{padding:0.5rem 0.625rem 0.625rem}.Highscore__table-3HVhIB tbody tr:nth-child(even){background-color:#f1f1f1}\n", ""]);

// Exports
exports.locals = {
	"container": "Highscore__container-YOgT4M",
	"subtitle": "Highscore__subtitle-hoDWym",
	"error": "Highscore__error-3v00AO",
	"loading": "Highscore__loading-3f2CcD",
	"table": "Highscore__table-3HVhIB"
};