import React, { Component } from 'react';
import NavigationMenu from 'components/Navigation/components/NavigationMenu/NavigationMenu';
import styles from 'components/Navigation/Navigation.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Container from 'components/Container/Container';

class Navigation extends Component {
  static propTypes = {
    className: PropTypes.string,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    link: '',
    brandAsset: null,
    text: '',
    className: '',
  };

  render() {
    const { location } = this.props;
    return (
      <div className={styles.wrapper}>
        <NavigationMenu location={location} />
      </div>
    );
  }
}

export default withRouter(Navigation);
