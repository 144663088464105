exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NdQuestPublisher__imageWrapper-18O7Qr{width:100%;margin:0 auto;margin:20px 0;text-align:center}.NdQuestPublisher__subLogo-29u6xn{width:100%;max-width:250px}.NdQuestPublisher__legalLinks-IgBDJz{display:flex;justify-content:center}.NdQuestPublisher__legalLink-dzg7-R{color:#000}.NdQuestPublisher__legalLink-dzg7-R:last-child{margin-left:1rem}.NdQuestPublisher__legalLink-dzg7-R:hover{color:#9fc75f;text-decoration:none}\n", ""]);

// Exports
exports.locals = {
	"imageWrapper": "NdQuestPublisher__imageWrapper-18O7Qr",
	"subLogo": "NdQuestPublisher__subLogo-29u6xn",
	"legalLinks": "NdQuestPublisher__legalLinks-IgBDJz",
	"legalLink": "NdQuestPublisher__legalLink-dzg7-R"
};