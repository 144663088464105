import api from 'api';
import { enc, qe } from 'helpers';

const initState = {
  user: {
    userId: '',
    name: '',
    points: 0,
    email: null,
    completedQuests: [],
    questsDone: {},
    character: null,
    medal: false,
  },
  character: null, // 'nilo',
  waitForUserAuthentication: true,
  notifications: [],
  quests: null,
  token: enc(btoa(qe({}))),
};

/*
*******
Actions
*******
*/

export const logoutUser = () => dispatch => {
  dispatch({
    type: 'USER_LOGGED_OUT',
  });
};

export const setCharacter = type => dispatch => {
  dispatch({
    type: 'SET_CHARACTER',
    payload: type,
  });
};
export const setUser = user => dispatch => {
  dispatch({
    type: 'SET_USER',
    payload: user,
  });
};

export const getQuests = () => dispatch =>
  api
    .quests()
    .all()
    .then(({ data }) =>
      dispatch({
        type: 'QUESTS_RECEIVED',
        payload: data,
      }),
    )
    .catch(() =>
      dispatch({
        type: 'QUESTS_FAILED',
      }),
    );

export const getUser = () => dispatch => {
  dispatch({
    type: 'USER_REQUESTED',
  });
  return api
    .users()
    .self()
    .then(data =>
      dispatch({
        type: 'USER_RECEIVED',
        payload: { data },
      }),
    )
    .catch(() =>
      dispatch({
        type: 'USER_NOT_AUTHENTICATED',
      }),
    );
};
export const addNotification = notification => dispatch => {
  dispatch({
    payload: { id: Math.random(), ...notification },
    type: 'ADD_NOTIFICATION',
  });
};

export const removeNotification = id => dispatch => {
  dispatch({
    payload: id,
    type: 'REMOVE_NOTIFICATION',
  });
};

export const setToken = token => dispatch => {
  dispatch({
    type: 'SET_TOKEN',
    payload: token,
  });
};

/*
*******
Reducer
*******
*/

export const app = (state = initState, action) => {
  switch (action.type) {
    case 'USER_RECEIVED':
      return {
        ...state,
        user: {
          ...action.payload.data.data.data,
        },
        waitForUserAuthentication: false,
      };
    case 'USER_NOT_AUTHENTICATED':
      return {
        ...state,
        waitForUserAuthentication: false,
      };
    case 'USER_LOGGED_OUT':
      return {
        ...state,
        user: null,
      };
    case 'SET_CHARACTER':
      return {
        ...state,
        character: action.payload,
      };
    case 'QUESTS_RECEIVED':
      return {
        ...state,
        quests: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case 'ADD_NOTIFICATION':
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };

    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(
            notification => notification.id !== action.payload,
          ),
        ],
      };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};
