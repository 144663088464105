import React, { useState } from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon/Icon';
import { wien, lupe } from 'components/Icon/list';

import styles from 'components/ViennaMap/ViennaMap.scss';

const ViennaMap = () => {
  const [active, setActive] = useState(false);

  return (
    <div className={styles.mapIconWrapper}>
      <Icon
        glyph={wien}
        className={classNames(styles.wienIcon, {
          [styles.active]: active,
        })}
      />
      <div onClick={() => setActive(!active)} role="button">
        <Icon glyph={lupe} className={styles.lupeIcon} />
      </div>
    </div>
  );
};

export default ViennaMap;
