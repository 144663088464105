import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from 'components/Container/Container.scss';
import Row from 'components/Container/components/Row/Row';
import Column from 'components/Container/components/Column/Column';

class Container extends Component {
  static propTypes = {
    full: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    full: false,
    className: '',
  };

  state = {};

  render() {
    const { children, full, className } = this.props;
    return (
      <div
        className={classNames(styles.container, className, {
          [styles.full]: full,
        })}
      >
        {children}
      </div>
    );
  }
}

export { Container as default, Row, Column };
