exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MonumentButton__container-RMCUqq{display:flex;height:48px;align-items:center;margin-bottom:10px;background-color:#333;color:#fff;font-size:18px}.MonumentButton__container-RMCUqq:active,.MonumentButton__container-RMCUqq:visited{color:#fff;text-decoration:none}.MonumentButton__iconContainer-1D3B1E{width:80px;margin-right:10px;background:linear-gradient(to bottom, #000 0%, #000 1%, #9fc75f 53%);border-bottom-right-radius:100px;border-top-right-radius:100px}.MonumentButton__icon-2f2dx8{height:48px;color:#fff}.MonumentButton__active-2n2JM2{background-color:#cc4c37}.MonumentButton__active-2n2JM2 .MonumentButton__iconContainer-1D3B1E{background:linear-gradient(to bottom, #000 0%, #000 1%, #cc4c37 53%)}\n", ""]);

// Exports
exports.locals = {
	"container": "MonumentButton__container-RMCUqq",
	"iconContainer": "MonumentButton__iconContainer-1D3B1E",
	"icon": "MonumentButton__icon-2f2dx8",
	"active": "MonumentButton__active-2n2JM2"
};