import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from 'components/Topbar/Topbar.scss';
import Container, { Row, Column } from 'components/Container/Container';
import Navigation from 'components/Navigation/Navigation';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import { logotree } from 'components/Icon/list';
import classNames from 'classnames';

class Topbar extends Component {
  state = {};

  static defaultProps = {
    points: null,
  };

  render() {
    const {
      points,
      absolute,
      user: { character },
      goHome,
    } = this.props;

    return (
      <div
        className={classNames(styles.container, {
          [styles.absolute]: absolute,
        })}
      >
        <Link to={goHome ? '/' : character ? '/monuments' : '/'}>
          <Icon glyph={logotree} className={styles.logo} />
        </Link>
        {points !== null && (
          <div className={styles.pointsHeader}>
            <div>Punkte {points}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Topbar);
