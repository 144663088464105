import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Wrapper from 'components/Wrapper/Wrapper';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { LastLocationProvider } from 'react-router-last-location';
import Header from 'components/Header/Header';
import Banner from 'components/Banner/Banner';
import Home from 'pages/Home/Home';
import Footer from 'pages/App/components/Footer/Footer';

import Instruction from 'pages/Instruction/Instruction';
import Monuments from 'pages/Monuments/Monuments';
import Map from 'pages/Map/Map';
import MonumentDetail from 'pages/MonumentDetail/MonumentDetail';

import Characters from 'pages/Characters/Characters';
import NameSelect from 'pages/NameSelect/NameSelect';
import QuestDetail from 'pages/QuestDetail/QuestDetail';
import Profile from 'pages/Profile/Profile';
import QuestQuestion from 'pages/QuestQuestion/QuestQuestion';
import CorrectAnswer from 'pages/CorrectAnswer/CorrectAnswer';
import Impressum from 'pages/Impressum/Impressum';
import Policy from 'pages/Policy/Policy';
import Highscore from 'pages/Highscore/Highscore';

function usePageViews() {
  const location = useLocation();

  React.useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_path: location.pathname + location.hash,
      });
    }
  }, [location]);
}

function PageViews() {
  usePageViews();

  return null;
}

function Pages() {
  return (
    <Wrapper>
      <PageViews />
      <LastLocationProvider>
        <Header />
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/instruction" component={Instruction} />
            <Route exact path="/monuments" component={Monuments} />
            <Route exact path="/characters" component={Characters} />
            <Route exact path="/nameselect" component={NameSelect} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/monuments/:id" component={MonumentDetail} />
            <Route exact path="/quests/:id" component={QuestDetail} />
            <Route
              exact
              path="/quests/:id/question/:qid"
              component={QuestQuestion}
            />
            <Route exact path="/map/:id?" component={Map} />
            <Route exact path="/correct" component={CorrectAnswer} />
            <Route exact path="/impressum" component={Impressum} />
            <Route exact path="/datenschutz" component={Policy} />
            <Route exact path="/highscore" component={Highscore} />
          </Switch>
        </ScrollToTop>
        <Banner />
        <Footer />
      </LastLocationProvider>
    </Wrapper>
  );
}

export default Pages;
