import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Icon extends Component {
  static checkPropTypeAriaLabel(props, propName) {
    if (props.role === 'img' && !props[propName]) {
      return new Error('Please provide an ariaLabel if role is img!');
    }
    return null;
  }

  static propTypes = {
    /** Required if role is not set to "presentation */
    ariaLabel: Icon.checkPropTypeAriaLabel,
    /** SVG Object */
    glyph: PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      node: PropTypes.object,
      viewBox: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    /** "Presentation" should only be used, if no valuable content for screenreaders is present */
    role: PropTypes.oneOf(['img', 'presentation']),
  };

  static defaultProps = {
    ariaLabel: null,
    className: null,
    role: 'presentation',
  };

  render() {
    const { ariaLabel, className, glyph, role } = this.props;
    const { id, viewBox } = glyph;
    if (!viewBox || !id) {
      return <span>{JSON.stringify(glyph)}</span>;
    }

    const svgAttr = {
      'aria-label': ariaLabel,
      className,
      role,
      viewBox,
      width: 96, // default height only. can get overwritten by css anytime
    };

    return (
      <svg {...svgAttr}>
        <use xlinkHref={`#${id}`} />
      </svg>
    );
  }
}

export default Icon;
