import React, { Component } from 'react';
import { Marker } from 'react-google-maps';

const marker = `<svg enable-background="new 0 0 38 50" viewBox="0 0 38 50" xmlns="http://www.w3.org/2000/svg"><path d="m36 18.8c0-9.5-7.7-17.2-17.2-17.2s-17.2 7.7-17.2 17.2c0 8 5.4 14.7 12.8 16.6l2.9 12.6h1.5 1.5l2.8-12.6c7.5-2 12.9-8.7 12.9-16.6z" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/><path d="m18.8 35.9c-1.5 0-3-.2-4.4-.6l2.9 12.7h1.5 1.5l2.8-12.6c-1.3.3-2.8.5-4.3.5z" fill="#607b3b"/><circle cx="18.8" cy="18.8" fill="#9fc75f" r="17.2"/><g fill="#fff"><path d="m17.6 24.8h-1.6l-6.8-10.3c.1 1.2.1 2.3.1 3.3v7h-1.3v-12.3h1.6l6.7 10.3h.1c0-.2 0-.6-.1-1.5 0-.8-.1-1.4 0-1.8v-7.1h1.3z"/><path d="m29.7 18.6c0 2-.6 3.6-1.7 4.7s-2.7 1.6-4.8 1.6h-3.4v-12.4h3.8c1.9 0 3.4.5 4.5 1.6s1.6 2.6 1.6 4.5zm-1.5 0c0-1.6-.4-2.8-1.2-3.6s-2-1.2-3.6-1.2h-2.1v9.8h1.7c1.7 0 3-.4 3.8-1.3 1-.8 1.4-2 1.4-3.7z"/></g></svg>`;
const selectedMarker = `<svg xmlns="http://www.w3.org/2000/svg" width="37.4" height="49.4" viewBox="0 0 37.4 49.4"><g transform="translate(-0.1 -0.1)"><path d="M36,18.8A17.2,17.2,0,1,0,14.4,35.4L17.3,48h3l2.8-12.6A17.228,17.228,0,0,0,36,18.8Z" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/><path d="M18.8,35.9a16.115,16.115,0,0,1-4.4-.6L17.3,48h3l2.8-12.6A19.5,19.5,0,0,1,18.8,35.9Z" fill="#6e6504"/><path d="M17.2,0A17.2,17.2,0,1,1,0,17.2,17.2,17.2,0,0,1,17.2,0Z" transform="translate(1.6 1.6)" fill="#ffeb00"/><path d="M17.6,24.8H16L9.2,14.5c.1,1.2.1,2.3.1,3.3v7H8V12.5H9.6l6.7,10.3h.1a12.417,12.417,0,0,0-.1-1.5,10.52,10.52,0,0,1,0-1.8V12.4h1.3Z" fill="#6e6504"/><path d="M29.7,18.6A6.434,6.434,0,0,1,28,23.3a6.485,6.485,0,0,1-4.8,1.6H19.8V12.5h3.8a6.054,6.054,0,0,1,4.5,1.6A6.054,6.054,0,0,1,29.7,18.6Zm-1.5,0A4.889,4.889,0,0,0,27,15a4.889,4.889,0,0,0-3.6-1.2H21.3v9.8H23a4.872,4.872,0,0,0,3.8-1.3,4.364,4.364,0,0,0,1.4-3.7Z" fill="#6e6504"/></g></svg>`;
const completedMarker = `<svg xmlns="http://www.w3.org/2000/svg" width="37.4" height="49.4" viewBox="0 0 37.4 49.4"><g transform="translate(-0.1 -0.1)"><path d="M36,18.8A17.2,17.2,0,1,0,14.4,35.4L17.3,48h3l2.8-12.6A17.228,17.228,0,0,0,36,18.8Z" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/><path d="M18.8,35.9a16.115,16.115,0,0,1-4.4-.6L17.3,48h3l2.8-12.6A19.5,19.5,0,0,1,18.8,35.9Z" fill="#5c221a"/><path d="M17.2,0A17.2,17.2,0,1,1,0,17.2,17.2,17.2,0,0,1,17.2,0Z" transform="translate(1.6 1.6)" fill="#c54230"/><path d="M17.6,24.8H16L9.2,14.5c.1,1.2.1,2.3.1,3.3v7H8V12.5H9.6l6.7,10.3h.1a12.417,12.417,0,0,0-.1-1.5,10.52,10.52,0,0,1,0-1.8V12.4h1.3Z" fill="#fff"/><path d="M29.7,18.6A6.434,6.434,0,0,1,28,23.3a6.485,6.485,0,0,1-4.8,1.6H19.8V12.5h3.8a6.054,6.054,0,0,1,4.5,1.6A6.054,6.054,0,0,1,29.7,18.6Zm-1.5,0A4.889,4.889,0,0,0,27,15a4.889,4.889,0,0,0-3.6-1.2H21.3v9.8H23a4.872,4.872,0,0,0,3.8-1.3,4.364,4.364,0,0,0,1.4-3.7Z" fill="#fff"/></g></svg>`;

export default class MapMarker extends Component {
  render() {
    const { options, completed, selected, ...props } = this.props;

    const markerUrl = `data:image/svg+xml;charset=UTF-8;base64,${btoa(marker)}`;
    const selectedMarkerUrl = `data:image/svg+xml;charset=UTF-8;base64,${btoa(
      selectedMarker,
    )}`;
    const completedMarkerUrl = `data:image/svg+xml;charset=UTF-8;base64,${btoa(
      completedMarker,
    )}`;

    let url = completed ? completedMarkerUrl : markerUrl;
    url = selected ? selectedMarkerUrl : url;

    const icon = {
      url,
      scaledSize: selected
        ? new window.google.maps.Size(60, 60)
        : new window.google.maps.Size(50, 50),
    };
    const zIndex = selected ? window.google.maps.Marker.MAX_ZINDEX + 1 : 0;

    return <Marker options={{ icon, zIndex, ...options }} {...props} />;
  }
}
