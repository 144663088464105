import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/Input/Input.scss';
import TextareaAutosize from 'react-autosize-textarea';
import * as Icons from 'components/Icon/list';
import Icon from 'components/Icon/Icon';

class Input extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    prefixClassName: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.oneOf([
      'text',
      'number',
      'email',
      'date',
      'search',
      'tel',
      'url',
      'password',
      'textarea',
      'hidden',
      'multiline',
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    large: PropTypes.bool,
    small: PropTypes.bool,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    disabled: PropTypes.bool,
    hint: PropTypes.string,
    children: PropTypes.node,
    status: PropTypes.oneOf(['normal', 'success', 'error', 'warning']),
    autoFocus: PropTypes.bool,
    htmlAutoFocus: PropTypes.bool,
    tabIndex: PropTypes.number,
    spellcheck: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    maxLength: PropTypes.number,
    onError: PropTypes.func,
    extendedOptionIcon: PropTypes.string,
    extendedOptionHandler: PropTypes.func,
    // just for textarea
    // Called whenever the textarea resizes
    onResize: PropTypes.func,
    // Minimum number of visible rows
    rows: PropTypes.number,
    // Maximum number of visible rows
    maxRows: PropTypes.number,
  };

  static defaultProps = {
    onChange: () => {},
    placeholder: '',
    children: null,
    prefix: '',
    disabled: false,
    hint: '',
    type: 'text',
    status: 'normal',
    spellcheck: false,
    small: false,
    className: '',
    maxLength: 0,
    value: '',
    autoFocus: false,
    htmlAutoFocus: false,
    tabIndex: 0,
    name: '',
    large: false,
    inputClassName: '',
    wrapperClassName: '',
    prefixClassName: '',
    id: '',
    onError: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onKeyUp: () => {},
    onKeyDown: () => {},
    onKeyPress: () => {},
    extendedOptionHandler: () => {},
    extendedOptionIcon: null,
    rows: 1,
    maxRows: 10 ** 100,
    onResize: () => {},
  };

  state = {
    isFocused: false,
    maxLengthLimitation: false,
  };

  componentDidMount() {
    this.autoFocus();
  }

  componentDidUpdate() {
    this.autoFocus();
  }

  setInput = element => {
    this.input = element;
  };

  handleBlur = event => {
    if (this.isAutoFocus()) {
      event.preventDefault();
      event.target.focus();

      return;
    }

    this.setState({ isFocused: false });

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  handleFocus = event => {
    this.setState({ isFocused: true });

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleChange = event => {
    this.props.onChange(event.target.value, event);
  };

  handleError = error => {
    this.props.onError(error);
  };

  isAutoFocus() {
    return Boolean(this.props.autoFocus) && !this.props.disabled;
  }

  autoFocus() {
    if (this.isAutoFocus() && this.input) {
      if (document.activeElement !== this.input) {
        this.input.focus();
      }
    }
  }

  focus() {
    if (this.input && document.activeElement !== this.input) {
      this.input.focus();
    }
  }

  blur() {
    if (this.input) {
      this.input.blur();
    }
  }

  renderCounter() {
    const { maxLengthLimitation } = this.state;
    const { maxLength, value } = this.props;
    if (!maxLength) {
      return null;
    }

    if (value) {
      if (value.length > maxLength) {
        this.setState({ maxLengthLimitation: true });
      } else {
        this.setState({ maxLengthLimitation: false });
      }
    }

    return (
      <div className={styles.counter}>
        <div
          className={classNames(styles.counterDisplay, {
            [styles.maxLengthLimitation]: maxLengthLimitation,
          })}
        >
          {value ? value.length : 0}
          {'/'}
          {maxLength}
        </div>
      </div>
    );
  }

  renderHint() {
    const { hint } = this.props;

    if (!hint) {
      return null;
    }

    return <p className={styles.hint}>{hint}</p>;
  }

  renderPrefix() {
    const { prefix, id } = this.props;

    if (!prefix) {
      return null;
    }
    const className = classNames(styles.prefix, this.props.prefixClassName);

    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <label
        htmlFor={id}
        className={className}
        onMouseDown={this.handleLabelMouseDown}
      >
        {prefix}
      </label>
    );
  }

  renderSuffix() {
    const { status } = this.props;

    const mappedElements = {
      success: Icons.acceptTick,
    };
    if (!mappedElements[status]) return null;

    return <Icon className={styles.suffix} glyph={mappedElements[status]} />;
  }

  renderExtendedOption() {
    const { extendedOptionIcon, extendedOptionHandler } = this.props;
    if (!extendedOptionIcon || !extendedOptionHandler) return null;

    return (
      <div role="presentation" onClick={extendedOptionHandler}>
        <Icon
          className={styles.extendedOption}
          glyph={Icons[extendedOptionIcon]}
        />
      </div>
    );
  }

  renderInput() {
    const {
      id,
      name,
      type,
      value,
      disabled,
      tabIndex,
      placeholder,
      children,
      htmlAutoFocus,
      onKeyUp,
      onKeyDown,
      onKeyPress,
      spellcheck,
    } = this.props;

    const props = {
      className: classNames(styles.input, this.props.inputClassName),
      disabled,
      id,
      name,
      placeholder: placeholder || null,
      type,
      value,
      tabIndex,
      autoFocus: htmlAutoFocus,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      onError: this.handleError,
      onKeyDown,
      onKeyPress,
      onKeyUp,
    };
    if (children) {
      return React.cloneElement(children, props);
    }
    if (type === 'textarea') {
      const { rows, maxRows, onResize } = this.props;
      return (
        <TextareaAutosize
          {...props}
          spellCheck={spellcheck ? 'true' : 'false'}
          rows={rows}
          maxRows={maxRows}
          onResize={onResize}
          // eslint-disable-next-line
          innerRef={ref => (this.setInput = ref)}
        />
      );
    }

    return (
      <input
        ref={this.setInput}
        {...props}
        spellCheck={spellcheck ? 'true' : 'false'}
      />
    );
  }

  render() {
    const {
      props: { value, disabled, status, large, small, maxLength },
      state: { isFocused, maxLengthLimitation },
    } = this;

    const className = classNames(
      styles.container,
      this.props.className,
      status ? styles[status] : null,
      value ? styles.filled : null,
      isFocused ? styles.focused : null,
      maxLengthLimitation ? styles.maxLengthLimitation : null,
      disabled ? styles.disabled : null,
      large ? styles.large : null,
      small ? styles.small : null,
      maxLength ? styles.counterLabel : null,
    );

    const wrapperClassName = classNames(
      styles.inputWrapper,
      this.props.wrapperClassName,
    );

    return (
      <div className={className}>
        <div className={wrapperClassName}>
          {this.renderPrefix()}
          {this.renderInput()}
          {this.renderSuffix()}
          {this.renderExtendedOption()}
        </div>
        {this.renderCounter()}
        {this.renderHint()}
      </div>
    );
  }
}

export default Input;
