exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Profile__container-OyT1wU{width:100%;height:100%;align-items:center;justify-content:center;padding:15px 30px;text-align:center}.Profile__character-2x6VI7{padding:15px;background-color:white;border-radius:30px}.Profile__characterIcon-1UAjRm{width:220px}.Profile__content-l7b0le{display:flex;flex-direction:column;align-items:center;padding:20px}.Profile__list-34k6v4{padding-left:15px}.Profile__hr-3di8GX{width:100%;margin:20px 0}.Profile__button-1805Of{width:80%;margin:0 auto;margin-top:20px}.Profile__message-mv36l-{color:orange;text-align:center}.Profile__emailInput-33pVYI{width:80%;margin:0 auto}\n", ""]);

// Exports
exports.locals = {
	"container": "Profile__container-OyT1wU",
	"character": "Profile__character-2x6VI7",
	"characterIcon": "Profile__characterIcon-1UAjRm",
	"content": "Profile__content-l7b0le",
	"list": "Profile__list-34k6v4",
	"hr": "Profile__hr-3di8GX",
	"button": "Profile__button-1805Of",
	"message": "Profile__message-mv36l-",
	"emailInput": "Profile__emailInput-33pVYI"
};