import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/Container/components/Column/Column.scss';

class Column extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    half: PropTypes.bool,
    full: PropTypes.bool,
    third: PropTypes.bool,
    twothird: PropTypes.bool,
    quarter: PropTypes.bool,
    twoquarter: PropTypes.bool,
    threequarter: PropTypes.bool,
    fill: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    half: false,
    full: false,
    third: false,
    twothird: false,
    quarter: false,
    twoquarter: false,
    threequarter: false,
    fill: false,
  };

  state = {};

  render() {
    const {
      children,
      className,
      half,
      full,
      third,
      twothird,
      quarter,
      twoquarter,
      fill,
      threequarter,
    } = this.props;
    return (
      <div
        className={classNames(styles.column, className, {
          [styles.half]: half,
          [styles.full]: full,
          [styles.quarter]: quarter,
          [styles.third]: third,
          [styles.twothird]: twothird,
          [styles.fill]: fill,
          [styles.quarter]: quarter,
          [styles.twoquarter]: twoquarter,
          [styles.threequarter]: threequarter,
          [styles.third]: third,
        })}
      >
        {children}
      </div>
    );
  }
}

export default Column;
