import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import styles from 'pages/QuestDetail/QuestDetail.scss';
import Heading from 'components/Heading/Heading';
import { connect } from 'react-redux';
import Icon from 'components/Icon/Icon';
import { question } from 'components/Icon/list';
import Topbar from 'components/Topbar/Topbar';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

class QuestDetail extends Component {
  state = {
    progress: 2,
    questionIcon: question,
  };

  render() {
    const {
      user: { character, completedQuests },
      quests,
      match,
    } = this.props;
    const { questionIcon } = this.state;

    // must login
    if (!character) {
      return <Redirect to="/characters" />;
    }

    if (!quests) {
      return null;
    }

    const shortcodeMd5 = match.params.id;
    const decodedShortcode = Object.keys(quests).find(
      shortcode => global.md5(shortcode) === shortcodeMd5,
    );

    const {
      title,
      subtitle,
      image_subtext: imageSubtext,
      text,
      shortcode,
      points,
      tasks,
    } = quests[decodedShortcode];

    if (completedQuests.includes(shortcode)) {
      return <Redirect to="/monuments" />;
    }

    const taskIds = tasks.map((task, i) => i);
    const questionId = getRandomInt(taskIds[0], taskIds[taskIds.length - 1]);

    return (
      <div className={styles.container}>
        <Topbar points={points} />
        <div className={styles.header}>
          <div className={styles.progressContainer}>
            <div className={styles.progress} />
          </div>
          <div className={styles.textContainer}>
            <Heading isSmall tag="h2" setHtml>
              {title}
            </Heading>
            <em dangerouslySetInnerHTML={{ __html: subtitle }} />
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <img
            src={`https://ndque.st/img/quests/${shortcode}.jpg`}
            alt={title}
          />
        </div>
        <div className={styles.content}>
          <p
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: imageSubtext }}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <div>
            <Link
              to={`/quests/${global.md5(shortcode)}/question/${questionId}`}
              className={styles.questionButton}
            >
              <Icon glyph={questionIcon} className={styles.questionIcon} />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user, quests } }) => ({
  user,
  quests,
});

export default withRouter(connect(mapStateToProps)(QuestDetail));
