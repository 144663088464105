exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Monuments__monumentsList-11W6t8{margin-bottom:10px}.Monuments__description-110fZ1{padding:0 20px;text-align:center}.Monuments__bottomText-1Ix7dY{margin-top:15px;text-align:center}.Monuments__bottomText-1Ix7dY span{color:red;text-transform:uppercase}.Monuments__noQuests-1-17_a{margin:2rem 0;color:tomato;text-align:center}\n", ""]);

// Exports
exports.locals = {
	"monumentsList": "Monuments__monumentsList-11W6t8",
	"description": "Monuments__description-110fZ1",
	"bottomText": "Monuments__bottomText-1Ix7dY",
	"noQuests": "Monuments__noQuests-1-17_a"
};