import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from 'components/Heading/Heading.scss';

class Heading extends Component {
  static propTypes = {
    isSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool,
    tag: PropTypes.string,
    children: PropTypes.node.isRequired,
    noPadding: PropTypes.bool,
    className: PropTypes.string,
    setHtml: PropTypes.bool,
    center: PropTypes.bool,
  };

  static defaultProps = {
    isSmall: false,
    isMedium: false,
    isLarge: false,
    tag: 'h1',
    noPadding: false,
    className: '',
    setHtml: false,
    center: false,
  };

  render() {
    const {
      tag,
      children,
      isLarge,
      isMedium,
      isSmall,
      noPadding,
      className,
      setHtml,
      center,
    } = this.props;
    const Element = { tag };

    return setHtml ? (
      <Element.tag
        className={classNames(styles.heading, className, styles[tag], {
          [styles.medium]: isMedium,
          [styles.small]: isSmall,
          [styles.large]: isLarge,
          [styles.noPadding]: noPadding,
          [styles.center]: center,
        })}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    ) : (
      <Element.tag
        className={classNames(styles.heading, className, styles[tag], {
          [styles.small]: isSmall,
          [styles.medium]: isMedium,
          [styles.large]: isLarge,
          [styles.noPadding]: noPadding,
          [styles.center]: center,
        })}
      >
        {children}
      </Element.tag>
    );
  }
}
export default Heading;
