import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styles from 'pages/Instruction/Instruction.scss';
import Heading from 'components/Heading/Heading';
import Content from 'components/Content/Content';
import ViennaMap from 'components/ViennaMap/ViennaMap';
import Icon from 'components/Icon/Icon';
import woImage from 'assets/images/button_wo_mittx.png';
import ndBaum from 'assets/images/icon_nd-green_mittext.png';

import { question } from 'components/Icon/list';
import Topbar from '../../components/Topbar/Topbar';

export default class Instructions extends Component {
  render() {
    return (
      <div className={styles.container}>
        <Topbar />
        <Grid>
          <Row>
            <Col>
              <Heading tag="h2">Spielanleitung</Heading>
              <Content>
                Die Naturdenkmäler (Bäume) sind über ganz Wien verstreut.
                <br />
                Wir haben für dich als angehenden Bontaik- und
                Naturdenkmälerexperten einige ausgewählt und für dich Aufgaben
                vor Ort gestellt.
              </Content>
              <br />
              <ViennaMap />
              <br />
              <Content withParser>
                Den Weg zeigt dir der Button "WO". Geo-Lokalisierung
                (Googlemaps).
              </Content>
              <img src={woImage} alt="Wo" />
              <hr />
              <Content withParser>
                Wenn du bereit bist zu spielen, drücke auf „START“ und melde
                dich als Spieler an.
              </Content>
              <img src={ndBaum} alt="" />
              <hr />
              <Content withParser>
                Nachdem du den Naturdenkmal-Baum gefunden hast, drücke den
                Button „ND-BAUM“. Suche nach der Plakette und gib die Ziffern
                ein
              </Content>
              <hr />
              <Content>
                Jetzt kannst du die Quest (Aufgabe) lösen. Lese zuerst die
                Informationen über das Naturdenkmal, dann drücke den Button „ZUR
                FRAGE“. Die Zeit läuft!
              </Content>
              <Icon glyph={question} className={styles.questionIcon} />
              <hr />
              <Heading tag="h2">Highscore</Heading>
              <Content>
                Nach 5 Quests bist du in der Highscoreliste eingetragen. Dann
                kannst du auch ein Diplom ausdrucken.
                <br /> Selbstverständlich kannst du Naturdenkmäler Wien Quest
                unterbrechen und später weiterspielen und damit weiter entfernte
                Naturdenkmäler später suchen.
              </Content>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
