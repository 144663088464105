import React, { Component } from 'react';
import styles from 'pages/NameSelect/NameSelect.scss';
import Heading from 'components/Heading/Heading';
import Content from 'components/Content/Content';
import Icon from 'components/Icon/Icon';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { setUser } from 'pages/App/App.state';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';

import { characters } from 'config';
import { createFingerprint } from 'helpers';
import Topbar from '../../components/Topbar/Topbar';

class NameSelect extends Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  static defaultProps = {
    user: {
      name: null,
    },
  };

  handleChange = name => {
    this.props.dispatch(setUser({ name }));
  };

  handleNext = () => {
    const {
      character,
      user: { name },
    } = this.props;
    if (name) {
      this.props.dispatch(setUser({ character, userId: createFingerprint() }));
      this.props.history.push('/profile');
    }
  };

  render() {
    if (!this.props.character) {
      return 'loading';
    }

    const {
      character,
      user: { name },
    } = this.props;
    return (
      <div className={styles.container}>
        <Topbar />

        <div className={styles.content}>
          <div className={styles.characterWrapper}>
            <div className={styles.character}>
              <Icon glyph={characters[character].icon} />
            </div>
          </div>

          <div>
            <Input onChange={this.handleChange} value={name} />
            <div className={styles.yourName}>dein Name</div>
          </div>

          <Button view="outline" onClick={this.handleNext}>
            weiter
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ app: { user, character } }) => ({
  user,
  character,
});

export default withRouter(connect(mapStateToProps)(NameSelect));
