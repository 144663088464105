import React, { Component } from 'react';
import styles from 'components/Notification/Notification.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Notification extends Component {
  static propTypes = {
    warning: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    warning: false,
  };

  render() {
    const { warning, children } = this.props;

    return (
      <div
        className={classNames(styles.container, {
          [styles.warning]: warning,
        })}
      >
        {children}
      </div>
    );
  }
}
export default Notification;
