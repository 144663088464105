exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MonumentDetail__wrapper-1L8jip{display:flex;min-height:100vh;align-items:center;justify-content:center;background:#3a5631;background:linear-gradient(90deg, #3a5631 0%, #6a582a 100%)}.MonumentDetail__logo-2T4IOo{width:100vw;height:200px;color:#9fc75f}.MonumentDetail__container-VE1s8u{display:grid;width:100%;height:100%;align-items:center;grid-template-rows:1fr 1fr}.MonumentDetail__imageWrapper-2ijmTT{width:100%;height:100%;background-position:50% 50%;background-repeat:no-repeat;background-size:contain}.MonumentDetail__content-3SrwkK{padding:0 20px}.MonumentDetail__description-3h75Xi{margin:20px 0;color:#fff;font-size:1.1rem;opacity:0.8;text-align:center}.MonumentDetail__objectCodeImageWrapper-BCd4Gl{display:flex;height:100px;justify-content:center;margin:20px 0;text-align:center}.MonumentDetail__objectCodeImageWrapper-BCd4Gl img{width:150px;height:150px;border-radius:50px}.MonumentDetail__inputWrapper-19Fa60{width:50%;margin:0 auto}.MonumentDetail__input-1d1iH8{padding:0 !important;font-size:2rem !important;text-align:center !important}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "MonumentDetail__wrapper-1L8jip",
	"logo": "MonumentDetail__logo-2T4IOo",
	"container": "MonumentDetail__container-VE1s8u",
	"imageWrapper": "MonumentDetail__imageWrapper-2ijmTT",
	"content": "MonumentDetail__content-3SrwkK",
	"description": "MonumentDetail__description-3h75Xi",
	"objectCodeImageWrapper": "MonumentDetail__objectCodeImageWrapper-BCd4Gl",
	"inputWrapper": "MonumentDetail__inputWrapper-19Fa60",
	"input": "MonumentDetail__input-1d1iH8"
};