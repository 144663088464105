import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Navigation/components/NavigationMenu/NavigationMenu.scss';
import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  crosshair,
  circle,
  baby,
  birdy,
  compi,
  dog,
  elifant,
  lemone,
  nilo,
  strauss,
  violetta,
} from 'components/Icon/list';

const characters = {
  baby: {
    icon: baby,
  },
  birdy: {
    icon: birdy,
  },
  compi: {
    icon: compi,
  },
  dog: {
    icon: dog,
  },
  elifant: {
    icon: elifant,
  },
  lemone: {
    icon: lemone,
  },
  nilo: {
    icon: nilo,
  },
  strauss: {
    icon: strauss,
  },
  violetta: {
    icon: violetta,
  },
};
class NavigationMenu extends Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      points: PropTypes.number,
    }),
  };

  static defaultProps = {
    user: {
      name: null,
      points: null,
      email: null,
    },
  };

  render() {
    const {
      user: { name, points, character },
    } = this.props;
    return (
      <div className={styles.container}>
        <ul className={styles.wrapper}>
          <Link className={styles.item} to="/map">
            <span className={styles.text}>wo</span>
            <Icon glyph={crosshair} className={styles.icon} />
          </Link>
          {!character && (
            <Link to="/characters" className={styles.item}>
              <span className={classNames(styles.text, styles.redText)}>
                Start
              </span>
            </Link>
          )}
          {character && (
            <Link to="/profile" className={styles.item}>
              <div className={styles.character}>
                <Icon
                  className={styles.characterIcon}
                  glyph={characters[character].icon}
                />
                {points !== 0 && <span className={styles.score}>{points}</span>}
              </div>
            </Link>
          )}
          <Link
            to="/monuments/new"
            className={classNames(styles.item, {
              [styles.redText]: !character,
            })}
          >
            <span className={styles.text}>ND Baum</span>
            <Icon glyph={circle} className={styles.icon} />
          </Link>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user } }) => ({
  user,
});

export default withRouter(connect(mapStateToProps)(NavigationMenu));
