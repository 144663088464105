import axios from 'axios';

const OAuthToken = 'OAuthToken';
const OAuthAccessToken = 'OAuthAccessToken';

class Client {
  /**
   *  initializes base parameters for the client.
   *  clientId and clientSecret and baseUrl
   *
   *  all other params will be used as axios options
   *
   *  @params {string} clientId - OAuth id
   *  @params {string} clientSecret - OAuth secret
   *  @params {string} baseUrl - API Base URL
   *  @params {object} models - request models of entities
   */
  constructor({ clientId, clientSecret, ...options }, models) {
    this.refreshingPromise = null;
    this.client = axios.create(options);
    this.client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    this.client.defaults.headers.common.Accept = 'application/json';

    this.init({ clientId, clientSecret });

    return { ...models(this.client), ...this.client };
  }

  init({ clientId = 'api-anon', clientSecret = 'api-anon' }) {
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    /* eslint-disable no-param-reassign */
    this.client.interceptors.request.use(
      config => {
        const token = Client.getAccessToken();
        if (token != null) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      err => Promise.reject(err),
    );
    return this;
  }

  getClient() {
    return this.client;
  }

  static getAccessToken() {
    try {
      return JSON.parse(localStorage.getItem(OAuthAccessToken));
    } catch (error) {
      return localStorage.removeItem(OAuthAccessToken);
    }
  }

  static saveToken(accessToken) {
    localStorage.removeItem(OAuthToken);
    localStorage.setItem(OAuthAccessToken, JSON.stringify(accessToken));
  }

  static removeToken() {
    return localStorage.removeItem(OAuthAccessToken);
  }
}

export default Client;
