import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { app } from 'pages/App/App.state';
import throttle from 'lodash-es/throttle';

/* eslint no-underscore-dangle: ["error", { "allow": ["__PRELOADED_STATE__", "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */

// load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// save state to localStorage
const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

const persistedState = loadState();
const reducers = combineReducers({ app });
const preloadedState = window.__PRELOADED_STATE__ || persistedState || {};
const isProduction = false;
const middleware = [promise(), thunk];

const composeEnhancers =
  (isProduction ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const appliedMiddleware = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(reducers, preloadedState, appliedMiddleware);
store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

export default store;
