import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from 'pages/MonumentDetail/MonumentDetail.scss';
import Input from 'components/Input/Input';
import Topbar from 'components/Topbar/Topbar';
import Icon from 'components/Icon/Icon';
import { logotree } from 'components/Icon/list';
import treeSign from 'assets/images/treeSign.png';

class MonumentDetail extends Component {
  state = {
    objectCode: '',
  };

  handleCodeChange = value => {
    if (!this.props.quests) {
      return null;
    }

    if (this.props.quests[value]) {
      this.props.history.push(`/quests/${global.md5(value)}`);
      return null;
    }
    if (value.length > 4) {
      this.setState({ objectCode: '' });
      return null;
    }
    this.setState({ objectCode: value });
  };

  render() {
    const { objectCode } = this.state;
    const {
      user: { completedQuests, character },
      match,
      quests,
    } = this.props;

    if (!character) {
      return <Redirect to="/characters" />;
    }

    const shortcodeMd5 = match.params.id;
    const shortcode = Object.keys(quests).find(
      code => global.md5(code) === shortcodeMd5,
    );
    const quest = quests[shortcode];

    if (completedQuests.includes(shortcode)) {
      alert('Du hast diesen Quest bereits erledigt. Weiter so!');
      return <Redirect to="/monuments" />;
    }

    return (
      <div className={styles.wrapper}>
        <Topbar absolute />
        <div className={styles.container}>
          {quest ? (
            <div
              className={styles.imageWrapper}
              style={{
                backgroundImage: `url(https://ndque.st/img/quests/pre${
                  quest.shortcode
                }.jpg)`,
              }}
            />
          ) : (
            <Icon glyph={logotree} className={styles.logo} />
          )}

          <div className={styles.content}>
            <p className={styles.description}>
              Der Baum trägt eine Zahl. <br /> Gib die Ziffern ein.
            </p>
            <Input
              wrapperClassName={styles.inputWrapper}
              inputClassName={styles.input}
              value={objectCode}
              onChange={this.handleCodeChange}
            />

            <div className={styles.objectCodeImageWrapper}>
              <img src={treeSign} alt="Symbolbild für Baumplakete" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user, quests } }) => ({
  user,
  quests,
});

export default withRouter(connect(mapStateToProps)(MonumentDetail));
