import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from 'pages/QuestQuestion/QuestQuestion.scss';
import Heading from 'components/Heading/Heading';
import QuestAnswer from 'components/QuestAnswer/QuestAnswer';
import Topbar from 'components/Topbar/Topbar';
import isEqual from 'lodash-es/isEqual';
import { enc, qd, qe, shuffle } from 'helpers';

import { setUser, setToken } from 'pages/App/App.state';
import { successSound, errorSound } from 'helpers';
import { medalPoints } from 'config';

const duration = 10;

class QuestDetail extends Component {
  state = {
    progress: 0,
    answers: [],
    points: 0,
    initialPoints: 0,
  };

  componentDidMount() {
    this.startTimer();
    this.getAnswers();
  }

  componentDidUpdate(prevProps) {
    const { quests } = this.props;
    if (!isEqual(quests, prevProps.quests)) {
      this.getAnswers();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startTimer = () => {
    const { match, history } = this.props;

    this.interval = setInterval(() => {
      if (this.state.progress < duration) {
        this.setState(prevState => ({
          progress: prevState.progress + 1,
          points:
            prevState.points - prevState.initialPoints / duration > 0
              ? prevState.points - prevState.initialPoints / duration
              : 0,
        }));
      } else {
        clearInterval(this.interval);
        alert('Zeit abgelaufen, probiers doch noch einmal!');
        history.replace(`/quests/${match.params.id}`);
      }
    }, 1500);
  };

  getAnswers = () => {
    const { quests, match } = this.props;
    const shortcodeMd5 = match.params.id;
    const decodedShortcode = Object.keys(quests).find(
      shortcode => global.md5(shortcode) === shortcodeMd5,
    );

    if (quests) {
      const { points, tasks } = quests[decodedShortcode];
      const task = tasks[match.params.qid];
      const answers = shuffle([
        { isCorrect: true, value: task.answer_correct },
        { value: task.answer_wrong1 },
        { value: task.answer_wrong2 },
        { value: task.answer_wrong3 },
      ]);
      this.setState({ initialPoints: points, points, answers });
    }
  };

  handleCorrectAnswer = () => {
    const { points } = this.state;
    const { user, quests, match, token } = this.props;

    const shortcodeMd5 = match.params.id;
    const decodedShortcode = Object.keys(quests).find(
      shortcode => global.md5(shortcode) === shortcodeMd5,
    );
    const { shortcode } = quests[decodedShortcode];

    const questObj = qd(atob(enc(token)));
    // if the ID is not set yet in token-object, set it
    if (!questObj.hasOwnProperty(shortcode)) {
      questObj[shortcode] = parseInt(points, 10);
    }

    const newToken = enc(btoa(qe(questObj)));

    this.props.dispatch(setToken(newToken));
    this.props.dispatch(
      setUser({
        completedQuests: [...user.completedQuests, shortcode],
        questsDone: { ...user.questsDone, [shortcode]: points },
        points: user.points + points,
      }),
    );
    successSound();
    this.props.history.replace('/correct');
  };

  handleInvalidAnswer = () => {
    errorSound();
    this.setState(prevState => ({
      progress: prevState.progress + 1, // 1 sekunden weniger zeit
      points:
        prevState.points - prevState.initialPoints / duration > 0
          ? prevState.points - prevState.initialPoints / duration
          : 0, // punkte um 20 reduzieren
    }));
  };

  render() {
    const { answers, points } = this.state;
    const { quests, match } = this.props;

    if (!quests) {
      return null;
    }

    const shortcodeMd5 = match.params.id;
    const decodedShortcode = Object.keys(quests).find(
      shortcode => global.md5(shortcode) === shortcodeMd5,
    );

    const { tasks } = quests[decodedShortcode];
    const task = tasks[match.params.qid];

    return (
      <div className={styles.container}>
        <Topbar points={points} />
        <div>
          <div className={styles.header}>
            <div className={styles.progressContainer}>
              <div
                className={styles.progress}
                style={{ width: `${100 - this.state.progress * duration}%` }}
              />
            </div>
            <Heading className={styles.heading} setHtml>
              {task.question}
            </Heading>
          </div>
          <div className={styles.answers}>
            {answers.map(({ isCorrect, value }, i) => (
              <QuestAnswer
                key={i}
                correct={isCorrect}
                onCorrectAnswer={() => this.handleCorrectAnswer(isCorrect)}
                onInvalidAnswer={() => this.handleInvalidAnswer()}
              >
                {value}
              </QuestAnswer>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { user, quests, token } }) => ({
  user,
  quests,
  token,
});

export default withRouter(connect(mapStateToProps)(QuestDetail));
