import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getQuests } from 'pages/App/App.state';

import Pages from 'pages/App/Pages';
import PropTypes from 'prop-types';

import 'dayjs/locale/de';

class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // Next line is to featch users from server. Commented to reuse on later stage
    this.props.dispatch(getQuests());
  }

  render() {
    return <Pages />;
  }
}

export default connect(() => ({}))(App);
export { App as RawApp };
