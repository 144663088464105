exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".QuestDetail__container-KcNnMD{min-height:100vh;background:#3a5631;background:linear-gradient(90deg, #3a5631 0%, #6a582a 100%)}.QuestDetail__imageWrapper-2VMzRx{width:100%;height:200px}.QuestDetail__imageWrapper-2VMzRx img{width:100%;height:100%;object-fit:cover}.QuestDetail__content-17bU9y{padding:10px 50px;color:#fff}.QuestDetail__title-17lMje{font-size:1.2rem;font-weight:bold}.QuestDetail__description-HpW-VH{margin:10px 0;line-height:1.5}.QuestDetail__questionButton-2n3-KQ{display:flex;justify-content:center;margin:30px 0;text-align:center}.QuestDetail__questionIcon-2bWS6U{width:150px;height:150px;border:2px solid rgba(0,0,0,0.2);border-radius:75px}.QuestDetail__header-2_-UuJ{position:relative;color:#fff}.QuestDetail__progressContainer-3lotNR{position:absolute;z-index:1;top:0;right:0;left:0;height:100%}.QuestDetail__progress-3IDGKZ{height:100%;background-color:#292c30;transition:width 0.2s ease-in}.QuestDetail__textContainer-2B7cZW{position:relative;z-index:2;padding-bottom:10px;padding-left:30px}\n", ""]);

// Exports
exports.locals = {
	"container": "QuestDetail__container-KcNnMD",
	"imageWrapper": "QuestDetail__imageWrapper-2VMzRx",
	"content": "QuestDetail__content-17bU9y",
	"title": "QuestDetail__title-17lMje",
	"description": "QuestDetail__description-HpW-VH",
	"questionButton": "QuestDetail__questionButton-2n3-KQ",
	"questionIcon": "QuestDetail__questionIcon-2bWS6U",
	"header": "QuestDetail__header-2_-UuJ",
	"progressContainer": "QuestDetail__progressContainer-3lotNR",
	"progress": "QuestDetail__progress-3IDGKZ",
	"textContainer": "QuestDetail__textContainer-2B7cZW"
};