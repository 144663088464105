import React, { Component } from 'react';
import styles from 'pages/App/components/Footer/Footer.scss';

class Icon extends Component {
  render() {
    return <div className={styles.container} />;
  }
}

export default Icon;
