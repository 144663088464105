exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Topbar__container-voPxE8{display:flex;align-items:center;justify-content:space-between;padding:5px 10px}.Topbar__absolute-1RcS2C{position:absolute;top:0;left:0}.Topbar__logo-Gozuvh{color:#9fc75f}.Topbar__pointsHeader-3MMT8Q{color:#9fc75f;font-size:1.2rem}\n", ""]);

// Exports
exports.locals = {
	"container": "Topbar__container-voPxE8",
	"absolute": "Topbar__absolute-1RcS2C",
	"logo": "Topbar__logo-Gozuvh",
	"pointsHeader": "Topbar__pointsHeader-3MMT8Q"
};