const models = client => ({
  quests: () => ({
    all: () => client.get('/apiv1/quests'),
  }),
  highscore: () => ({
    all: () => client.get('/apiv1/highscore'),
    update: params => client.get('/apiv1/updateHighscore', { params }),
  }),
});
export default models;
