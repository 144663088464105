import React, { Component } from 'react';
import Button from 'components/Button/Button';

import styles from 'pages/Home/Home.scss';
import { logo } from 'components/Icon/list';
import Icon from 'components/Icon/Icon';
import NdQuestPublisher from 'components/NdQuestPublisher/NdQuestPublisher';

export default class Home extends Component {
  render() {
    return (
      <div className={styles.container}>
        <Icon glyph={logo} className={styles.logo} />
        <br />
        <br />
        <br />
        <br />
        <Button to="/monuments" wide className={styles.button}>
          Orte/ND-Bäume
        </Button>
        <Button to="/instruction" wide className={styles.button}>
          Spielanleitung
        </Button>
        <Button to="/characters" wide className={styles.button}>
          Anmeldung
        </Button>
        <br />
        <NdQuestPublisher />
      </div>
    );
  }
}
