import Client from '@moonshiner/client';

import models from 'models';

const api = new Client(
  {
    baseURL: API_BASE,
    clientId: CLIENT_ID,
    clientSecret: CLIENT_SECRET,
  },
  models,
);
export default api;
