exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".QuestAnswer__answer-3xQ8Fx{display:grid;align-items:center;margin:10px 0;color:#fff;grid-gap:20px;grid-template-columns:auto 1fr}.QuestAnswer__bullet-akpEK-{width:50px;height:50px;background-color:#95a08a;border-radius:25px;box-shadow:0 14px 28px rgba(0,0,0,0.25),0 10px 10px rgba(0,0,0,0.22);transition:background-color 0.5s ease-in-out}.QuestAnswer__answerText-2ZxjFT{font-size:20px;text-align:left}.QuestAnswer__correct-3jEvWk{background-color:greenyellow}.QuestAnswer__wrong-3WZtIn{background-color:red}\n", ""]);

// Exports
exports.locals = {
	"answer": "QuestAnswer__answer-3xQ8Fx",
	"bullet": "QuestAnswer__bullet-akpEK-",
	"answerText": "QuestAnswer__answerText-2ZxjFT",
	"correct": "QuestAnswer__correct-3jEvWk",
	"wrong": "QuestAnswer__wrong-3WZtIn"
};