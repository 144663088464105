exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NameSelect__container-1mUjIZ{height:100%;min-height:100vh;background:#3a5631;background:linear-gradient(90deg, #3a5631 0%, #6a582a 100%)}.NameSelect__content-1xNUq3{display:grid;height:calc(100vh - 73px - 75px);align-items:center;justify-content:center;padding:5% 15px;grid-gap:10px;grid-template-rows:auto 1fr auto}.NameSelect__characterWrapper-218V7j{width:70%;margin:0 auto}.NameSelect__character-1J-bdb{padding:15px;background-color:white;border-radius:30px}.NameSelect__yourName-1aCnVz{padding:10px 0;color:#fff;font-size:1rem;opacity:0.8;text-align:center}\n", ""]);

// Exports
exports.locals = {
	"container": "NameSelect__container-1mUjIZ",
	"content": "NameSelect__content-1xNUq3",
	"characterWrapper": "NameSelect__characterWrapper-218V7j",
	"character": "NameSelect__character-1J-bdb",
	"yourName": "NameSelect__yourName-1aCnVz"
};