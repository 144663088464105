import React, { Component } from 'react';
import styles from 'pages/Characters/Characters.scss';
import Heading from 'components/Heading/Heading';
import Icon from 'components/Icon/Icon';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setCharacter } from 'pages/App/App.state';
import {
  baby,
  birdy,
  compi,
  dog,
  elifant,
  lemone,
  nilo,
  strauss,
  violetta,
} from 'components/Icon/list';
import Topbar from 'components/Topbar/Topbar';

const characters = {
  baby: {
    id: 0,
    icon: baby,
  },
  birdy: {
    id: 1,
    icon: birdy,
  },
  compi: {
    id: 2,
    icon: compi,
  },
  dog: {
    id: 3,
    icon: dog,
  },
  elifant: {
    id: 4,
    icon: elifant,
  },
  lemone: {
    id: 5,
    icon: lemone,
  },
  nilo: {
    id: 6,
    icon: nilo,
  },
  strauss: {
    id: 7,
    icon: strauss,
  },
  violetta: {
    id: 8,
    icon: violetta,
  },
};
class Characters extends Component {
  setCharacter = type => {
    this.props.dispatch(setCharacter(type));
    this.props.history.push('/nameselect');
  };

  render() {
    return (
      <div className={styles.container}>
        <Topbar />
        <Heading className={styles.heading}>Charaktäre</Heading>
        <br />
        <div className={styles.characters}>
          {Object.keys(characters).map(key => {
            const character = characters[key];
            return (
              <div
                key={character.id}
                className={styles.character}
                onClick={() => this.setCharacter(key)}
              >
                <Icon glyph={character.icon} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(Characters));
