import React, { Component } from 'react';
import styles from 'components/Header/Header.scss';
import Container, { Row, Column } from 'components/Container/Container';
import Navigation from 'components/Navigation/Navigation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notification from 'components/Notification/Notification';
import { removeNotification } from 'pages/App/App.state';

class Header extends Component {
  static propTypes = {
    user: PropTypes.shape({
      firstname: PropTypes.string,
    }),
    header: PropTypes.arrayOf(PropTypes.shape({})),
    notifications: PropTypes.arrayOf(PropTypes.shape({})),
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
    header: [],
    notifications: [],
  };

  state = {};

  render() {
    const { user, notifications } = this.props;

    return (
      <div>
        <div className={styles.header}>
          <Navigation user={user} text="moonshiner" />
        </div>

        {notifications && (
          <Container full>
            <Row>
              <Column full className={styles.notification}>
                {notifications.map(({ id, message, type, sticky, fade }) => (
                  <Notification
                    message={message}
                    isSticky={sticky}
                    type={type}
                    fade={fade}
                    key={id}
                    dismissable
                    onDismissClick={() =>
                      this.props.dispatch(removeNotification(id))
                    }
                  />
                ))}
              </Column>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { user, notifications } = state.app;
  return {
    user,
    notifications,
  };
};

export default connect(mapStateToProps)(Header);
